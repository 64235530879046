<template>
  <div style="padding: 20px; height: 100%">
    <el-form :inline="true" class="demo-form-inline">
      <div class="df aic">
        <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
          <el-input
            placeholder="关键词"
            v-model="query.keyword"
            clearable
            class="input-with-select"
            @clear="onSubmit"
          >
          </el-input>
        </el-form-item>
        <el-button
          type="success"
          size="medium"
          @click="onSubmit"
          class="mr10"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          >查询</el-button
        >
      </div>
    </el-form>
    <el-table :data="list" style="width: 100%">
      <el-table-column
        prop="warehouseName"
        label="库房名称"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="state" label="库房状态" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.state == 1" style="color: #67c23a">启用</span>
          <span v-else style="color: #f56c6c">禁用</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="companyName"
        label="所属企业"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="density"
        label="密度(kg/m³)"
        width="300"
        align="center"
      >
        <template slot-scope="scope">
          <div class="df aic jcc" style="width: 100%">
            <div v-if="!scope.row.isEdit" class="df">
              <span>{{ scope.row.density }}</span>
              <div
                style="height: 100%; width: 40px"
                v-if="scope.row.density"
              ></div>
              <i
                @click="
                  density = scope.row.density;
                  scope.row.isEdit = true;
                "
                class="el-icon-edit"
                style="font-size: 24px; color: #409eff"
              ></i>
            </div>
            <div v-else class="df aic" style="width: 100%">
              <div style="flex: 1">
                <el-input v-model="density" placeholder="请输入密度">
                  <template slot="append">kg/m³</template>
                </el-input>
              </div>
              <i
                @click="scope.row.isEdit = false"
                class="el-icon-close"
                style="font-size: 24px; margin: 0 10px; color: #f56c6c"
              ></i>
              <i
                @click="updateDensity(scope.row)"
                class="el-icon-check"
                style="font-size: 24px; margin: 0 10px; color: #409eff"
              ></i>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="warehouseTypeNames"
        label="绑定公式"
        width="300"
        align="center"
      >
        <template slot-scope="scope">
          <span :style="scope.row.formulaName ? '' : 'color:#909399'">
            {{ scope.row.formulaName ? scope.row.formulaName : "未绑定" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.formulaName"
            type="text"
            class="disib"
            size="mini"
            @click="handleBind(scope.row)"
            >绑定</el-button
          >
          <el-button
            v-else
            type="text"
            class="disib"
            style="color: #f56c6c"
            size="mini"
            @click="cancelBind(scope.row)"
            >取消绑定</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="df" style="justify-content: flex-end; padding: 20px 0">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="绑定公式"
      :visible.sync="formulaVisible"
      @close="closeFormulaVisible"
      width="40%"
    >
      <el-form
        ref="formulaForm"
        :model="formulaForm"
        label-width="100px"
        style="width: 60%"
      >
        <el-form-item size="medium" label="绑定公式">
          <div class="df aic">
            <el-autocomplete
              v-model="formulaForm.formulaKey"
              :fetch-suggestions="formulaQuerySearchAsync"
              placeholder="请输入公式名称"
              @select="handleFormulaSelect"
            >
              <template slot-scope="{ item }">
                <span class="name">{{ item.formulaName }}</span>
                <span
                  class="addr"
                  style="font-size: 12px; color: silver; float: right"
                  >传感器数量:{{ item.sensorNum }}</span
                >
              </template>
            </el-autocomplete>
            <div style="margin-left: 10px; flex: 1">
              <el-input
                v-if="formulaForm.formula"
                type="text"
                style="width: 100%"
                v-model="formulaForm.formula"
                disabled
              ></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          size="medium"
          :label="'sensor' + (o - 1)"
          v-for="o in formulaForm.sensorNum"
          :key="'sensor' + o"
        >
          <div class="df aic">
            <el-autocomplete
              v-model="formulaForm.sensorArr[o - 1]['name']"
              :fetch-suggestions="
                (queryString, cb) => sensorQuerySearchAsync(queryString, cb)
              "
              placeholder="请绑定对应传感器"
              @select="(item) => handleSensorSelect(item, o - 1)"
            >
              <template slot-scope="{ item }">
                <span class="name">{{ item.deviceName }}</span>
                <span
                  class="addr"
                  style="font-size: 12px; color: silver; float: right"
                  >{{ item.companyName }}</span
                >
              </template>
            </el-autocomplete>
            <div style="margin-left: 10px; flex: 1">
              <el-input
                type="number"
                v-if="formulaForm.sensorArr[o - 1]['unit']"
                placeholder="请输入传感器默认值"
                v-model="formulaForm.sensorArr[o - 1]['default']"
              >
                <template slot="append">{{
                  formulaForm.sensorArr[o - 1]["unit"]
                }}</template>
              </el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          size="medium"
          :label="'num' + (o - 1)"
          v-for="o in formulaForm.constNum"
          :key="'num' + o"
        >
          <el-input
            type="number"
            v-model="formulaForm.constArr[o - 1]['num' + o]"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formulaVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFormula">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/util/extra-api";
export default {
  data() {
    return {
      list: [],
      query: {
        limit: 15,
        page: 1,
        state: "",
        keyword: "",
      },
      total: 0,
      formulaVisible: false,
      formulaForm: {
        formulaKey: "",
        formula: "",
        constNum: null,
        sensorNum: null,
        sensorArr: [],
        constArr: [],
        formulaId: null,
        formulaSensorId: null,
        checkedId: null,
      },
      density: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    updateDensity(row) {
      api
        .post("/v1/wx/warehouse/updateDensity", {
          warehouseId: row.warehouseId,
          density: this.density,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getList();
          }
        });
    },
    cancelBind(row) {
      api
        .post("/v1/wx/formula/cancelWarehouseBind", {
          warehouseId: row.warehouseId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.getList();
          }
        });
    },
    formulaQuerySearchAsync(queryString, cb) {
      if (queryString) {
        // console.log(queryString);
        api
          .get(`/v1/wx/formula/list`, {
            keyword: queryString,
          })
          .then((res) => {
            if (res.code == 200) {
              let results = res.data;
              // console.log(results);
              cb(results);
            }
          })
          .catch((err) => {
            cb([]);
          });
      } else {
        cb([]);
      }
    },
    closeFormulaVisible() {
      this.formulaForm = {
        formulaKey: "",
        formula: "",
        constNum: null,
        sensorNum: null,
        sensorArr: [],
        constArr: [],
        formulaId: null,
        formulaSensorId: null,
      };
    },
    pageChange(page) {
      this.query.page = page;
      this.getList();
    },
    getList() {
      api.get("/v1/wx/warehouse/pcList", this.query).then((res) => {
        this.list = res.data.map((v) => {
          return {
            ...v,
            isEdit: false,
          };
        });
        this.total = res.total;
      });
    },
    onSubmit() {
      this.query.page = 1;
      this.getList();
    },
    handleBind(data) {
      this.checkedId = data.warehouseId;
      this.formulaVisible = true;
    },
    handleSensorSelect(item, index) {
      this.formulaForm.sensorArr[index]["sensor" + index] = item.id;
      this.formulaForm.sensorArr[index]["name"] = item.deviceName;
      this.formulaForm.sensorArr[index]["unit"] = item.unit;
    },
    sensorQuerySearchAsync(queryString, cb) {
      if (queryString) {
        api
          .get(`/v1/wx/formula/selectSensor`, {
            keyword: queryString,
          })
          .then((res) => {
            if (res.code == 200) {
              let results = res.data;
              // console.log(results);
              cb(results);
            }
          })
          .catch((err) => {
            cb([]);
          });
      } else {
        cb([]);
      }
    },
    handleFormulaSelect(item) {
      this.formulaForm.formulaKey = item.formulaName;
      this.formulaForm.formula = item.formula;
      this.formulaForm.formulaId = item.id;
      for (let i = 0; i < item.constNum; i++) {
        this.formulaForm.constArr.push({});
      }
      for (let i = 0; i < item.sensorNum; i++) {
        this.formulaForm.sensorArr.push({ name: "" });
        this.formulaForm.sensorArr[i]["sensor" + i] = null;
      }
      // console.log(this.formulaForm);
      this.formulaForm.constNum = item.constNum;
      this.formulaForm.sensorNum = item.sensorNum;
    },
    submitFormula(data) {
      let constList = {},
        sensorList = {};
      let flag = false;
      this.formulaForm.sensorArr.forEach((v, i) => {
        if(!v.name){
          flag = true;
        }
       sensorList["sensor" + i] = {
          id: v["sensor" + i],
          name: v.name,
          default: v.default,
          unit: v.unit,
        };
      });
       if(flag){
        this.$message.error("传感器不能为空");
        return;
      }
      this.formulaForm.constArr.forEach((v, i) => {
        if (!Number(v["num" + (i + 1)])) {
          flag = true;
        }
        constList["num" + i] = Number(v["num" + (i + 1)]);
      });
      // if(flag){
      //   this.$message.error("常量不能为空");
      //   return;
      // }
      api
        .post("/v1/wx/formula/warehouseBind", {
          warehouseId: this.checkedId,
          formulaId: this.formulaForm.formulaId,
          constList: constList,
          sensorList: sensorList,
        })
        .then((res) => {
          this.getList();
          this.formulaVisible = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table__empty-block {
  width: 100% !important;
}
.jcc {
  justify-content: center;
}
</style>
