<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>AI模型</el-breadcrumb-item>
        <el-breadcrumb-item>模型列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <div style="padding: 0 20px">
      <div class="df aic" style="margin-bottom: 20px">
        <el-button type="success" @click="addVisible = true">新增</el-button>
      </div>
      <el-table border :data="list" style="width: 100%">
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="modeName" label="模型名称" width="180">
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" width="180">
        </el-table-column>
        <el-table-column prop="value" label="接口" width="180">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="update(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              class="disib red"
              size="mini"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page fr disib mt20">
        <el-pagination
          background
          :page-size="query.limit"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="query.page"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="新增" :visible.sync="addVisible" width="60%">
      <div
        style="
          height: 700px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 0 20px;
          box-sizing: border-box;
        "
        class="add-dialog-box"
      >
        <el-form ref="form" :model="addForm" label-width="80px">
          <el-form-item label="接口地址">
            <div style="width: 400px; margin-right: 10px">
              <el-input v-model="addForm.value"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="模型名称">
            <div class="df aic">
              <div style="flex: 0 0 300px; margin-right: 10px">
                <el-input v-model="addForm.modeName"></el-input>
              </div>
              <el-button
                type="primary"
                size="small"
                @click="addProperty"
                style="height: 38px"
                >增加属性</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <el-table :data="addForm.modeStep" style="width: 100%" :border="true">
          <el-table-column prop="name" label="属性" width="180">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.name"
                placeholder="请输入属性名称"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="field" label="字段" width="180">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.field"
                placeholder="请输入属性字段"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="info" label="输出项">
            <template slot-scope="scope">
              <el-tag
                v-for="(tag, tagIndex) in scope.row.info"
                :key="tagIndex"
                closable
                style="margin: 5px; margin-right: 5px"
                :type="['success', 'warning', 'danger'][tagIndex % 3]"
                size="medium"
              >
                {{ tag }}
              </el-tag>
              <el-popover
                placement="right"
                width="400"
                trigger="click"
                @hide="dataValue = ''"
              >
                <div style="padding-top: 10px">
                  <el-form ref="form" label-width="80px">
                    <el-form-item label="输出项">
                      <div style="width: 70%">
                        <el-input v-model="dataValue"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        size="small"
                        @click="addResult(scope.row)"
                        >增加属性</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
                <el-button
                  slot="reference"
                  type="warning"
                  size="mini"
                  style="margin: 5px; margin-right: 5px"
                  >新增输出</el-button
                >
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAdd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改" :visible.sync="updateVisible" width="60%">
      <div
        style="
          height: 700px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding: 0 20px;
          box-sizing: border-box;
        "
        class="add-dialog-box"
      >
        <el-form ref="form" :model="addForm" label-width="80px">
          <el-form-item label="接口地址">
            <div style="width: 400px; margin-right: 10px">
              <el-input v-model="addForm.value"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="模型名称">
            <div class="df aic">
              <div style="flex: 0 0 300px; margin-right: 10px">
                <el-input v-model="addForm.modeName"></el-input>
              </div>
              <el-button
                type="primary"
                size="small"
                @click="addProperty"
                style="height: 38px"
                >增加属性</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <el-table :data="addForm.modeStep" style="width: 100%" :border="true">
          <el-table-column prop="name" label="属性" width="180">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.name"
                placeholder="请输入属性名称"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="field" label="字段" width="180">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.field"
                placeholder="请输入属性字段"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="info" label="输出项">
            <template slot-scope="scope">
              <el-tag
                v-for="(tag, tagIndex) in scope.row.info"
                :key="tagIndex"
                closable
                style="margin: 5px; margin-right: 5px"
                :type="['success', 'warning', 'danger'][tagIndex % 3]"
                size="medium"
                @close="tagClose(scope.row, tag)"
              >
                {{ tag }}
              </el-tag>
              <el-popover
                placement="right"
                width="400"
                trigger="click"
                @hide="dataValue = ''"
              >
                <div style="padding-top: 10px">
                  <el-form ref="form" label-width="80px">
                    <el-form-item label="输出项">
                      <div style="width: 70%">
                        <el-input v-model="dataValue"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <el-button
                        type="primary"
                        size="small"
                        @click="addResult(scope.row)"
                        >增加属性</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
                <el-button
                  slot="reference"
                  type="warning"
                  size="mini"
                  style="margin: 5px; margin-right: 5px"
                  >新增输出</el-button
                >
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import api from "@/util/extra-api";

export default {
  data() {
    return {
      addVisible: false,
      addForm: {
        modeName: "",
        modeStep: [],
        value: "",
      },
      dataValue: "",
      query: {
        page: 1,
        limit: 10,
        type: 1,
      },
      list: [],
      total: 0,
      updateVisible: false,
      chooseId: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleUpdate() {
      api
        .post("/v1/pc/device/updateMode", {
          id: this.chooseId,
          modeName: this.addForm.modeName,
          modeStep: JSON.stringify(this.addForm.modeStep),
          value: this.addForm.value,
          type: 1,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.updateVisible = false;
          this.getList();
        });
    },
    tagClose(row, tag) {
      row.info = row.info.filter((v) => v !== tag);
    },
    update(row) {
      this.chooseId = row.id;
      this.addForm.modeName = row.modeName;
      this.addForm.value = row.value;
      this.addForm.modeStep = JSON.parse(row.modeStep);
      console.log(this.addForm, "测试");
      this.updateVisible = true;
    },
    handleDelete(row) {
      api.post("/v1/pc/device/deleteMode", { id: row.id }).then((res) => [
        this.$message({
          type: "success",
          message: "删除成功",
        }),
        this.getList(),
      ]);
    },
    currentChange(page) {
      this.query.page = page;
      this.getList();
    },
    getList() {
      api.get("/v1/pc/device/modeList", this.query).then((res) => {
        this.list = res.data;
        this.total = res.total;
      });
    },
    handleClose() {},
    addProperty() {
      this.addForm.modeStep.push({ name: "", field: "", info: [] });
      // this.$set(this.addForm, "property", [
      //   ...this.addForm.property,
      //   {
      //     date: "",
      //     name: "",
      //     address: "",
      //   },
      // ]);
    },
    addResult(row) {
      if (this.dataValue) {
        row.info.push(this.dataValue);
        this.dataValue = "";
      }
    },
    handleAdd() {
      // console.log(
      //   {
      //     modeName: this.addForm.modeName,
      //     modeStep: JSON.stringify(this.addForm.modeStep),
      //   },
      //   123
      // );
      api
        .post("/v1/pc/device/addMode", {
          modeName: this.addForm.modeName,
          modeStep: JSON.stringify(this.addForm.modeStep),
          value: this.addForm.value,
          type: 1,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.addVisible = false;
          this.getList();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-dialog-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.add-dialog-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}
/deep/ .el-dialog {
  border-radius: 10px;
}
</style>