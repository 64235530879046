
<template>
  <div id="index" ref="appRef">
    <div class="screen-bg">
      <dv-loading v-if="!show">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#000000']" />
            <div class="title">
              <span class="title-text">经营收支大数据</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="['#568aea', '#000000']"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-4 react-l-s">
              <span class="react-before"></span>
              <span class="text">
                <!--                                <p-->
                <!--                                        class="disib mr15"-->
                <!--                                        style="float: left; line-height: 60px"-->
                <!--                                        @click="Collapse"-->
                <!--                                >-->
                <!--                                    <i class="iconfont iconshouqi c000 mr5"></i>-->
                <!--                                </p>-->

                <el-dropdown
                  v-if="
                    company_name &&
                    isMenu == 2 &&
                    (user.user_type == 3 || user.user_type == 4)
                  "
                  class="ml20"
                  :hide-on-click="false"
                  @command="changeCompany"
                >
                  <div
                    class="f16 col-fff"
                    style="display: flex; align-items: center; cursor: pointer"
                  >
                    <div></div>
                    <img
                      style="padding: 2px; width: 30px; height: 30px"
                      src="@/assets/image/shouye.png"
                    />
                    {{ company_name }}
                  </div>
                  <el-dropdown-menu
                    slot="dropdown"
                    style="height: auto; padding: 5px"
                  >
                    <el-dropdown-item
                      v-for="(item, index) in companyData"
                      :key="index"
                      :command="item"
                      >{{ item.company_name }}
                    </el-dropdown-item>
                    <!-- <el-dropdown-item v-if="user.user_type==3" command="company">企业切换</el-dropdown-item>
                                            <el-dropdown-item command="logout">退出</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown
                  class="ml20 mr20"
                  :hide-on-click="false"
                  @command="LoginOut"
                >
                  <div
                    class="f16 col-fff"
                    style="
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                      margin-left: 10px;
                    "
                  >
                    <div></div>
                    <img
                      style="padding: 2px; width: 30px; height: 30px"
                      src="@/assets/image/my.png"
                    />
                    {{ user.user_name }}
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      style="height: auto; padding: 5px"
                      command="logout"
                      >退出</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
            <div class="react-right ml-3">
              <!--                            <span class="text">数据分析2</span>-->
            </div>
          </div>
          <div class="d-flex aside-width">
            <div class="react-right mr-3">
              <!--                            <span class="text fw-b">vue-big-screen</span>-->
            </div>
            <div class="react-right mr-4 react-l-s">
              <!--                            <span  class="react-after l80 f16 type-title">-->
              <!--                                {{typeTitle}}-->
              <!--                            </span>-->

              <span class="react-after1 l100">
                <el-popover
                  placement="bottom"
                  width="100%"
                  v-model="showPopover1"
                  transition="el-zoom-in-top"
                >
                  <div class="box-popover">
                    <div @click="conditionFilter(1)" class="tc bgc-1A3 col-fff">
                      条件筛选
                    </div>
                    <div @click="conditionFilter(0)" class="tc bgc-1A3 col-fff">
                      全部
                    </div>
                  </div>
                  <div slot="reference" class="d-flex row-center">
                    <div class="f16 type-title">
                      {{ typeTitle }}
                    </div>
                    <div>
                      <i class="el-icon-s-data"></i>
                    </div>
                  </div> </el-popover
              ></span>
              <span class="react-after l200">
                <el-popover
                  placement="bottom"
                  width="100%"
                  v-model="showPopover"
                  transition="el-zoom-in-top"
                >
                  <div class="box-popover">
                    <div @click="showSetting" class="tc bgc-1A3 col-fff">
                      配置
                    </div>
                  </div>
                  <i slot="reference" class="el-icon-s-tools"></i> </el-popover
              ></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div class="flex-4">
              <dv-border-box-12 v-if="show">
                <sinan />

                <!--                                <dynamic-list/>-->
              </dv-border-box-12>
            </div>
            <div class="flex-4">
              <dv-border-box-12 v-if="show">
                <pyramid-trend :screenType="0" />
              </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div class="box-center flex-8">
              <dv-border-box-10>
                <dv-border-box-8>
                  <el-popover
                    placement="bottom"
                    width="100%"
                    trigger="click"
                    v-model="showTotalPopover"
                    transition="el-zoom-in-top"
                  >
                    <div style="padding: 20px">
                      <div class="p15 tc f16">时间筛选</div>
                      <div>
                        <el-date-picker
                          v-model="value1"
                          type="datetimerange"
                          align="right"
                          unlink-panels
                          :clearable="false"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                      </div>
                      <div class="mt10 tr">
                        <button class="btn-reset" @click="timeConfirm1(0)">
                          重置
                        </button>
                        <button class="btn-add" @click="timeConfirm1(1)">
                          确定
                        </button>
                      </div>
                    </div>
                    <!--          <el-button slot="reference">click 激活</el-button>-->
                    <div slot="reference" class="box-i-time1">
                      <i class="el-icon-time i-time"></i>
                    </div>
                  </el-popover>
                  <p style="height: 40%; font-size: 38px; text-align: center">
                    <dv-decoration-7>收支统算</dv-decoration-7>
                  </p>
                  <p style="height: 40%; font-size: 60px">
                    <countTo
                      :startVal="startMoney"
                      :endVal="totalMoney"
                      :duration="3000"
                      suffix="元"
                      :decimals="decimals"
                    ></countTo>
                  </p>
                </dv-border-box-8>
              </dv-border-box-10>
              <div class="mt10">
                <dv-border-box-10>
                  <centerLeft1 :data="chart1Data" :screen-type="screenType" />
                </dv-border-box-10>
              </div>
            </div>
            <div class="flex-8">
              <dv-border-box-13 v-if="show">
                <seamless :screenType="screenType" />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bototm-box">
            <div class="flex-8">
              <dv-border-box-13>
                <cake-linkage />
              </dv-border-box-13>
            </div>

            <div class="flex-16">
              <dv-border-box-13>
                <bottomLeft />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第五行数据 -->
          <div class="bototm-box">
            <div class="flex-8">
              <dv-border-box-13>
                <sz-bar />
              </dv-border-box-13>
            </div>

            <div class="flex-8">
              <dv-border-box-13>
                <dragBar />
              </dv-border-box-13>
            </div>

            <div class="flex-8">
              <dv-border-box-13>
                <seamless1 />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第六行数据 -->
          <div class="bottom-box">
            <div>
              <dv-border-box-13>
                <szHistogram />
              </dv-border-box-13>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <template slot="title">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelectMenu"
          active-text-color="#2468a2"
        >
          <el-menu-item index="1">重点物资配置</el-menu-item>
          <el-menu-item index="2">制售品配置</el-menu-item>
        </el-menu>
      </template>
      <div class="tl p10i">
        <span>
          <el-input
            prefix-icon="el-icon-search"
            placeholder="请输入搜索内容"
            v-model="listQuery.keyword"
            width="200px"
            clearable
          >
          </el-input>
        </span>
        <span>
          <button class="btn-add" @click="getGoodsAndMaterialsList">
            搜索
          </button>
        </span>
      </div>
      <el-table ref="multipleTable" :data="gridData">
        <!--                <el-table-column-->
        <!--                        type="selection"-->
        <!--                        width="55">-->
        <!--                </el-table-column>-->
        <el-table-column
          property="goodsName"
          label="名称"
          width="150"
        ></el-table-column>
        <el-table-column
          property="gtin"
          label="编号"
          width="200"
        ></el-table-column>
        <el-table-column
          property="createdTime"
          width="200"
          label="创建时间"
        ></el-table-column>
        <el-table-column width="50" label="操作">
          <template slot-scope="scope">
            <button
              class="btn-del"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除
            </button>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt10">
        <el-pagination
          layout="prev, pager, next"
          :current-page="currentPage"
          @current-change="currentChange"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="p30 tr">
        <button class="btn-add" @click="refreshScreen">刷新数据</button>
        <button class="btn-add" @click="dialogAddgsVisible = true">添加</button>
      </div>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="dialogAddgsVisible"
      @close="closeDialogAddgsVisible"
    >
      <el-autocomplete
        placeholder="请输入物资名称"
        v-model="goodsInfo.goodsName"
        :fetch-suggestions="querySearchAsync"
        clearable
        style="width: 50%"
        class="input-with-select"
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <span class="name">{{ item.goodsName }}</span>
          <span
            class="gtin"
            style="font-size: 12px; color: silver; float: right"
            >{{ item.gtin }}</span
          >
        </template>
      </el-autocomplete>
      <button class="btn-add" @click="addGoods">添加</button>
    </el-dialog>
  </div>
</template>

<script>
import drawMixin from "@/util/drawMixin";
import { formatTime } from "@/util/util";

import centerLeft1 from "./components/centerLeft1";
import countTo from "vue-count-to";
import api from "../../../util/extra-api";
// import centerLeft2 from './centerLeft2'
// import centerRight2 from './centerRight2'
// import center from './center'
import bottomLeft from "./components/bottomLeft";
import szHistogram from "./components/szHistogram";
import dragBar from "./components/dragBar";
import axios from "../../../util/api";
import { local } from "../../../util/util";
// import bottomRight from './bottomRight'

export default {
  mixins: [drawMixin],
  props: {
    companyData: {
      type: Array,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      companyId: null,
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      startMoney: 0,
      totalMoney: 0,
      chart1Data: null,
      chart2Data: null,
      decimals: 2,
      show: false,
      showPopover: false,
      showPopover1: false,
      dialogTableVisible: false,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      gridData: [],
      activeIndex: "1",
      dialogAddgsVisible: false,
      title: "添加物资",
      autoPlaceholder: "请输入物资名称",
      goodsInfo: {
        goodsName: "",
        gtin: "",
      },
      keyword: "",
      menuType: 0,
      total: null,
      currentPage: null,
      typeTitle: "全部",
      type: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      startTime1: "",
      endTime1: "",
      showTotalPopover: false,
      screenType: 0,
      company_name: local.get("company_name"),
      isMenu: local.get("isMenu"),
    };
  },
  components: {
    centerLeft1,
    countTo,
    // centerLeft2,
    // centerRight2,
    // center,
    bottomLeft,
    szHistogram,
    dragBar,
    // bottomRight
  },
  mounted() {
    if (localStorage.getItem("time5")) {
      let time1 = JSON.parse(localStorage.getItem("time5"));
      this.value1 = [time1.startTime, time1.endTime];
      this.startTime1 = time1.startTime;
      this.endTime1 = time1.endTime;
    }

    this.companyId = localStorage.getItem("company_id");
    this.getType();
    this.timeFn();
    this.getChart1();
    this.getChart1_2();
    this.cancelLoading();
    this.getGoodsAndMaterialsList();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeConfirm1(type) {
      if (type === 1) {
        this.startTime1 = formatTime(this.value1[0], "yyyy-MM-dd HH:mm:ss");
        this.endTime1 = formatTime(this.value1[1], "yyyy-MM-dd HH:mm:ss");
      } else {
        this.startTime1 = "";
        this.endTime1 = "";
        this.value1 = ["", ""];
      }
      let time1 = {
        startTime: this.startTime1,
        endTime: this.endTime1,
      };
      localStorage.setItem("time5", JSON.stringify(time1));

      this.getChart1_2();
      this.showTotalPopover = false;
    },
    getType() {
      this.type = localStorage.getItem("type");
      if (this.type == 0 || !this.type) {
        this.typeTitle = "全部";
      } else {
        this.typeTitle = "条件筛选";
      }
    },
    conditionFilter(type) {
      localStorage.setItem("type", type);
      location.reload();
    },
    refreshScreen() {
      location.reload();
    },
    currentChange(page) {
      this.currentPage = page;
      this.listQuery.page = page;
      this.getGoodsAndMaterialsList();
    },
    showSetting() {
      this.dialogTableVisible = true;
      this.getGoodsAndMaterialsList();
    },
    addGoods() {
      let data = {
        companyId: this.companyId,
        gtin: this.goodsInfo.gtin,
        type: this.menuType,
      };
      api
        .post("/v1/wx/companyScreen/settingGoodsAndMaterials", data)
        .then((res) => {
          if (res.code === 200) {
            this.dialogAddgsVisible = false;
            this.getGoodsAndMaterialsList();
            for (let key in this.goodsInfo) {
              this.goodsInfo[key] = "";
            }
          }
        });
    },
    handleSelect(item) {
      this.goodsInfo.goodsName = item.goodsName;
      this.goodsInfo.gtin = item.gtin;
    },
    handleSelectMenu(index) {
      this.menuType = index - 1;
      this.listQuery.page = 1;
      this.getGoodsAndMaterialsList();
    },
    handleDelete(index, row) {
      //console.log(index, row);
      let data = {
        companyId: this.companyId,
        id: row.id,
      };
      api
        .get("/v1/wx/companyScreen/deleteGoodsAndMaterials", data)
        .then((res) => {
          if (res.code === 200) {
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
            });
            this.getGoodsAndMaterialsList();
          }
        });
    },
    getGoodsAndMaterialsList() {
      let data = {
        companyId: this.companyId,
        type: this.menuType,
        ...this.listQuery,
      };
      api
        .get("/v1/wx/companyScreen/goodsAndMaterialsList", data)
        .then((res) => {
          //console.log("goodsAndMaterialsList",res.data)
          if (res.code === 200) {
            this.gridData = res.data;
            this.total = res.total;
          }
        });
    },
    closeDialogAddgsVisible() {
      this.dialogAddgsVisible = false;
    },
    querySearchAsync(queryString, cb) {
      if (queryString) {
        api
          .get("/v1/wx/goods/pcGoodsList", {
            keyword: this.goodsInfo.goodsName,
            companyId: this.companyId,
            type: this.menuType,
          })
          .then((response) => {
            let results = response.data;
            cb(results);
          });
      } else {
        cb([]);
      }
    },
    getChart1() {
      let data = {
        companyId: this.companyId,
        type: this.type,
      };
      api.get("/v1/wx/companyScreen/chart1", data).then((res) => {
        if (res.code === 200) {
          this.chart1Data = Object.assign({}, res.data);
          this.chart2Data = res.data.companyGoodsRankLists;
          // this.totalMoney = res.data.pureProfit
          this.show = true;
        }
      });
    },
    getChart1_2() {
      let data = {
        companyId: this.companyId,
        type: this.type,
        startTime: this.startTime1,
        endTime: this.endTime1,
      };
      api.get("/v1/wx/companyScreen/chart1_2", data).then((res) => {
        if (res.code === 200) {
          this.totalMoney = res.data.pureProfit;
        }
      });
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    changeCompany(command) {
      axios
        .get("/pc/user/single", { company_id: String(command.company_id) })
        .then((response) => {
          local.set("user", response.data.user);
        });
      local.set("isMenu", 2);
      local.set("company_id", command.company_id);
      local.set("company_name", command.company_name);
      this.goCompany(command.company_id);
    },
    goCompany(params) {
      axios
        .put("/pc/user-role/refresh/menu", { last_login_company_id: params })
        .then((v) => {
          this.menu_list = v.data.menu_list;
          local.set("menu_list", this.menu_list);
          this.url = v.data.menu_list[0].menu_list[0].menu_url;
          this.company_name = local.get("company_name");
          if (this.$route.path !== this.url) {
            this.$router.replace({
              path: this.url,
            });
          }
        });
    },
    LoginOut(command) {
      let token = local.get("token");
      if (command === "logout") {
        axios.get("/pc/user/logOut", { token: token }).then((v) => {
          local.clear();
          this.$router.replace({
            path: "/login",
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/index.scss";
@import "../../../assets/scss/style.scss";
.el-menu.el-menu--horizontal {
  display: flex;
  justify-content: space-around;
}
.mt10 {
  margin-top: 10px !important;
}
.el-input {
  width: 50% !important;
}
.p10i {
  padding: 0 0 10px 0 !important;
}
</style>
