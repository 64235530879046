<template>
  <el-container class="shopList">
    <div>
      <el-header class="box add">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>企业摄像头配置列表</el-breadcrumb-item>
          <el-breadcrumb-item>摄像头列表</el-breadcrumb-item>
        </el-breadcrumb>
      </el-header>
      <div style="padding: 0 20px">
        <el-form :inline="true" :model="query" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="摄像头名称/id"
                v-model="query.keyword"
                clearable
                @clear="search"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="search"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
        <div>
          <el-table border :data="list" style="width: 100%">
            <el-table-column type="index" label="序号" min-width="55">
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="企业名称"
              :show-overflow-tooltip="true"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="camName"
              label="摄像头名称"
              min-width="180"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="camId"
              label="摄像头id"
              :show-overflow-tooltip="true"
              min-width="180"
            >
            </el-table-column>
            <el-table-column prop="siteId" label="站点id" min-width="180">
            </el-table-column>
            <el-table-column prop="siteId" label="直播权限" min-width="180">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isPlay"
                  active-text="允许直播"
                  inactive-text="禁止直播"
                  @change="(value) => changePlay(value, scope.row)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="200">
              <template slot-scope="scope">
                <el-popover
                  v-if="scope.row.isPlay"
                  placement="left"
                  width="400"
                  trigger="click"
                  @show="showPopover(scope.row)"
                  v-model="scope.row.isPopover"
                >
                  <div style="padding: 10px 0px">
                    <el-form ref="form" :model="form" label-width="120px">
                      <el-form-item label="限制播放流量" v-if="form.isFlow">
                        <div style="width: 65%">
                          <el-input v-model="form.playFlow">
                            <template slot="append">M</template>
                          </el-input>
                        </div>
                      </el-form-item>
                      <el-form-item label="限制播放时长" v-else>
                        <div style="width: 65%">
                          <el-input v-model="form.playNum">
                            <template slot="append">s</template>
                          </el-input>
                        </div>
                      </el-form-item>
                      <el-form-item label="类型" v-if="scope.row.isPopover">
                        <el-switch
                          v-model="form.isFlow"
                          active-text="限制流量"
                          inactive-text="限制时长"
                          @change="(value) => flowChange(value, scope.row)"
                        >
                        </el-switch>
                      </el-form-item>
                    </el-form>
                    <div style="justify-content: flex-end;padding:0 20px" class="df">
                      <el-button
                        style="margin-right: 4px"
                        size="mini"
                        @click="clearData(scope.row)"
                        >清空</el-button
                      >
                      <el-button
                        type="success"
                        size="mini"
                        @click="submit(scope.row)"
                        >配置</el-button
                      >
                    </div>
                  </div>
                  <el-button
                    type="text"
                    class="disib blue"
                    size="mini"
                    slot="reference"
                    @click="openDialog(scope.row)"
                    >配置</el-button
                  >
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <div class="i-page fr disib mt20">
            <el-pagination
              background
              :page-size="query.limit"
              layout="total,prev, pager, next"
              :total="total"
              :current-page="query.page"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- <el-dialog
      :title="handleName + title"
      :visible.sync="dialogAddgsVisible"
      @close="closeDialogAddgsVisible"
      width="30%"
    >
      <el-form
        :rules="rules"
        ref="form3"
        :model="form3"
        label-position="right"
        label-width="200px"
      >
        <el-form-item label="摄像头id:" prop="cam_id">
          <el-input
            placeholder="摄像头id"
            v-model="form3.cam_id"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="站点id:" prop="site_id">
          <el-input
            placeholder="站点id"
            v-model="form3.site_id"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="摄像头名称:" prop="cam_name">
          <el-input
            placeholder="摄像头名称"
            v-model="form3.cam_name"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="是否对外开放:">
          <div class="tl">
            <el-switch
              v-model="form3.show2"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item size="medium" label="是否对监管开放:">
          <div class="tl">
            <el-switch
              v-model="form3.show1"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </div>
        </el-form-item>
        <el-form-item label="摄像头详情:">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="摄像头详情"
            v-model="form3.detail"
            clearable
            style="width: 60%; float: left"
          >
          </el-input>
        </el-form-item>
        <el-button type="primary" size="medium" @click="submit">{{
          handleName
        }}</el-button>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="videoTitle"
      :visible.sync="videoShow"
      @close="closeDialogAddgsVisible"
    >
      <iframe
        :src="videoUrl"
        frameborder="0"
        width="100%"
        height="980px"
        scrolling="auto"
        ref="video-player"
      ></iframe>
    </el-dialog> -->

    <!-- <el-dialog
      :title="titleTxt"
      :visible.sync="picsVisible"
      width="35%"
      @close="
        imageSrc = null;
        AIData = null;
        AIType = 'body_attr';
      "
    >
      <div>
        <div
          v-if="titleTxt === 'AI应用'"
          class="df"
          style="margin-bottom: 10px"
        >
          <el-switch
            v-model="AIType"
            active-value="face_rec"
            inactive-value="body_attr"
            active-text="人脸识别"
            inactive-text="移动侦测"
            @change="switchChange"
          >
          </el-switch>
        </div>
        <div>
          <el-date-picker
            v-model="timeValue"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 100%"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            @change="handleTimeChange"
          >
          </el-date-picker>
        </div>
        <div style="margin-top: 10px" v-loading="picLoading">
          <el-image
            :src="imageSrc"
            :preview-src-list="[imageSrc]"
            style="width: 100%"
            v-if="imageSrc"
          >
          </el-image>
          <div
            v-else
            class="df aic jcc"
            style="
              color: #bbb;
              height: 100px;
              background-color: rgba(0, 0, 0, 0.2);
              margin-bottom: 10px;
            "
          >
            {{ noImageFlag ? "该摄像头暂无开起该功能" : "没有更多" }}
          </div>
        </div>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6" style="text-align: center">
            <el-button
              size="mini"
              @click="handlePre"
              v-if="titleTxt === '留影回看'"
              >上一张</el-button
            >
            <el-button size="mini" @click="handleAI(0)" v-else
              >上一张</el-button
            >
          </el-col>

          <el-col :span="6" style="text-align: center">
            <el-button
              size="mini"
              @click="handleNext"
              v-if="titleTxt === '留影回看'"
              >下一张</el-button
            >
            <el-button size="mini" @click="handleAI(1)" v-else
              >下一张</el-button
            >
          </el-col>
        </el-row>
      </div>
      <div v-if="titleTxt === 'AI应用' && AIData && AIData.length">
        <div v-for="(item, index) in AIData" :key="'AI' + index">
          <div v-if="AIType === 'body_attr'">
            <div class="df">识别人数:{{ item.person_num }}人</div>
            <div class="df" style="flex-wrap: wrap">
              <div
                v-for="(v, i) in item.person_info"
                :key="'person' + i"
                class="df ai-item"
              >
                <div class="ai-info">
                  <div class="info">
                    {{ v.attributes.gender.name }}
                  </div>
                  <div class="title">性别</div>
                </div>
                <div class="gap"></div>

                <div class="ai-info">
                  <div class="info">{{ v.attributes.face_mask.name }}</div>
                  <div class="title">是否佩戴口罩</div>
                </div>
                <div class="gap"></div>

                <div class="ai-info">
                  <div class="info">{{ v.attributes.headwear.name }}</div>
                  <div class="title">是否佩戴帽子</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="df" v-if="item.result">
              识别人数:{{ item.result.length }}人
            </div>
            <div class="df" v-else>未检测到人脸</div>
            <div class="df" style="flex-wrap: wrap">
              <div
                class="df ai-item"
                v-for="(v, i) in item.result"
                :key="'face' + i"
              >
                <div class="ai-info">
                  <div class="info">{{ v.subjects[0].subject }}</div>
                  <div class="title">识别结果</div>
                </div>
                <div class="gap"></div>
                <div class="ai-info">
                  <div class="info">
                    {{ (v.subjects[0].similarity * 100).toFixed(2) }}%
                  </div>
                  <div class="title">概率</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="权限管理" :visible.sync="manageVisible" width="35%">
      <div>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedUser"
          @change="handleCheckedCitiesChange"
          size="medium"
        >
          <el-checkbox
            style="width: 20%; margin: 20px"
            v-for="user in userList"
            :label="user.user_id"
            :key="user.user_id"
            :border="true"
            ><div>{{ user.user_name }}</div></el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div>
        <el-button type="primary" round @click="handleSaveUser">保存</el-button>
      </div>
    </el-dialog> -->
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import api from "@/util/extra-api";
import dayjs from "dayjs";
import LiveVideo from "../DepGragh/components/LiveVideo";
export default {
  components: {
    // LiveVideo,
  },
  data() {
    return {
      query: {
        limit: 18,
        page: 1,
        keyword: "",
      },
      list: [],
      total: 0,
      form: {
        playFlow: "",
        playNum: "",
        isFlow: false,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    submit(row) {
      this.settingCamPlay(
        {
          isPlay: true,
          playFlow: this.form.playFlow ? this.form.playFlow : -1,
          playNum: this.form.playNum ? this.form.playNum : -1,
          camId: row.id,
        },
        row
      );
    },
    clearData(row) {
      this.settingCamPlay(
        {
          isPlay: true,
          playFlow: -1,
          playNum: -1,
          camId: row.id,
        },
        row
      );
    },
    flowChange(value, row) {
      this.form.playFlow = value
        ? row.playFlow !== -1
          ? row.playFlow
          : ""
        : "";
      this.form.playNum = !value ? (row.playNum !== -1 ? row.playNum : "") : "";
      this.form.isFlow = value;
    },
    showPopover(row) {
      this.form.playFlow = row.playFlow === -1 ? "" : row.playFlow;
      this.form.playNum = row.playNum === -1 ? "" : row.playNum;
      this.form.isFlow = row.playFlow !== -1;
    },
    changePlay(value, row) {
      //   console.log(row, value, 321);
      this.settingCamPlay({
        isPlay: value,
        playFlow: row.playFlow,
        playNum: row.playNum,
        camId: row.id,
      });
    },
    settingCamPlay(data, row) {
      api.post("/v1/pc/device/settingCamPlay", data).then((res) => {
        if (res.code === 200) {
          if (row) {
            row.isPlay = false;
          }
          this.getList();
        }
      });
    },
    getList() {
      api.get("/v1/pc/device/camList", this.query).then((res) => {
        if (res.code === 200) {
          this.list = res.data.map((v) => ({
            ...v,
            isPopover: false,
          }));
          this.total = res.total;
        }
      });
    },
    currentChange(page) {
      this.query.page = page;
      this.getList();
    },
    search() {
      this.query.page = 1;
      this.getList();
    },
    openDialog() {},
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
$color: #2194e0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.activated {
  color: #1d953f;
}
.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}
.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  /*height: 20vw*/
}

.ai-item {
  border: 1px solid #eee;
  margin-top: 10px;
  margin-right: 10px;
  .ai-info {
    margin: 10px;
    padding: 0 5px;
    .info {
      line-height: 26px;
      font-size: 18px;
      background: none;
    }
    .title {
      line-height: 14px;
      color: #bbb;
      font-size: 12px;
    }
  }
  .gap {
    margin: 15px 0;
    width: 1px;
    background-color: #ccc;
  }
}
/deep/.el-table__empty-block {
  width: 100% !important;
}
</style>
