<template>
  <div style="padding: 20px; height: 100%">
    <el-form :inline="true" class="demo-form-inline">
      <div class="df aic">
        <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
          <el-input
            placeholder="关键词"
            v-model="query.keyword"
            clearable
            class="input-with-select"
            @clear="onSubmit"
          >
          </el-input>
        </el-form-item>
        <el-button
          type="success"
          size="medium"
          @click="onSubmit"
          class="mr10"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          >查询</el-button
        >
      </div>
    </el-form>
    <el-table :data="companyList" style="width: 100%">
      <el-table-column
        prop="companyName"
        label="企业名称"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="creditCode"
        label="统一社会信用代码"
        width="300"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="companyType"
        label="企业类型"
        width="300"
        align="center"
      >
      </el-table-column>

      <el-table-column prop="address" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="disib"
            size="mini"
            @click="handleOpenDialog(scope.row)"
            >模板配置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="df" style="justify-content: flex-end; padding: 20px 0">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
      <el-dialog
        title="追溯模板设置"
        :visible.sync="dialogVisible"
        width="400px"
        custom-class="daily-box"
      >
        <div style="height: 800px">
          <div
            class="df"
            style="
              height: 100%;
              width: 100%;
              padding: 10px 20px 0;
              flex-direction: column;
            "
          >
            <div style="flex: 1">
              <el-checkbox
                v-model="item.checked"
                :label="item.label"
                border
                size="medium"
                v-for="item in templateList"
                :key="item.type"
              ></el-checkbox>
            </div>
            <div class="df jcc" style="flex-direction: row; padding: 20px 0">
              <el-button
                type="success"
                size="medium"
                @click="handleSubmit"
                class="mr10"
                style="
                  height: 36px;
                  border: none !important;
                  margin-bottom: 20px;
                "
                >提交</el-button
              >
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "@/util/extra-api";

export default {
  data() {
    return {
      companyList: [],
      total: 0,
      query: {
        keyword: "",
        limit: 18,
        page: 1,
      },
      checkedCompany: null,
      dialogVisible: false,
      templateList: [],
    };
  },
  mounted() {
    this.getCompanyList();
    this.getTemplateList();
  },
  methods: {
    getCompanyList() {
      api.get("/v1/pc/company/list", this.query).then((res) => {
        if (res.code == 200) {
          this.companyList = res.data;
          this.total = res.total;
        }
      });
    },
    onSubmit() {
      this.query.page = 1;
      this.getCompanyList();
    },
    pageChange(page) {
      this.query.page = page;
      this.getCompanyList();
    },
    getTemplateList() {
      api
        .get("/v1/wx/dic/data", {
          typeCode: "h5_template",
        })
        .then((res) => {
          if (res.code == 200) {
            this.templateList = res.data.map((v) => {
              return {
                ...v,
                checked: false,
              };
            });
          }
        });
    },
    handleSubmit() {
      api
        .post("/v1/wx/permissions/pc/auth", {
          companyId: this.checkedCompany,
          moduleIds: this.templateList
            .filter((v) => v.checked)
            .map((v) => v.id),
          type: "h5_template",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "设置成功",
            });
        
          }
        });
    },
    handleOpenDialog(row) {
      this.checkedCompany = row.companyId;
      api
        .get("/v1/wx/permissions/pc/authUser1", {
          companyId: row.companyId,
          type: "h5_template",
        })
        .then((res) => {
          if (res.code == 200) {
            this.templateList.forEach((v) => {
              v.checked = res.data.includes(v.id);
            });
            this.dialogVisible = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-checkbox {
  width: 100% !important;
  margin: 5px 0 !important;
  text-align: left !important;
  padding: 10px !important;
  height: auto !important;
}
</style>