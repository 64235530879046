<template>
  <div class="container">
    <!--header-->
    <div class="header1">
      <div class="bg_header">
        <div class="header_nav fl t_title">产品追溯大屏</div>
      </div>
    </div>

    <!--main-->
    <div class="data_content">
      <div class="data_head">
        <el-form :inline="true">
          <el-form-item>
            <input
              v-model="gtin"
              placeholder="条形码/二维码值(查询时必填)"
              style="width: 240px"
              class="input_box"
            />
          </el-form-item>
          <el-form-item label="">
            <input
              v-model="batchNumber"
              placeholder="批号(非必填)"
              style="width: 200px"
              class="input_box"
            />
          </el-form-item>
          <el-form-item>
            <input
              v-model="quarantineCertNumber"
              placeholder="检验检疫证号(非必填)"
              style="width: 200px"
              class="input_box"
            />
          </el-form-item>
          <el-form-item>
            <input
              v-model="carNumber"
              placeholder="车牌号(非必填)"
              style="width: 200px"
              class="input_box"
            />
          </el-form-item>
          <el-form-item>
            <span class="button" @click="handleSearch">查询</span>
            <span class="button" @click="handleRest">重置</span>
          </el-form-item>
          <el-form-item>
            <span
              style="
                display: inline-block;
                height: 18px;
                width: 32px;
                border-radius: 15px;
                vertical-align: middle;
                background-color: #7ffd23;
              "
            ></span>
            <span style="font-size: 14px; margin-right: 5px">商品发货点</span>
            <span
              style="
                display: inline-block;
                height: 18px;
                width: 32px;
                border-radius: 15px;
                vertical-align: middle;
                background-color: #23e6fd;
              "
            ></span>
            <span style="font-size: 14px; margin-right: 5px">商品收货点</span>
            <span
              style="
                display: inline-block;
                height: 18px;
                width: 32px;
                border-radius: 15px;
                vertical-align: middle;
                background-color: #ed1941;
              "
            ></span>
            <span style="font-size: 14px; margin-right: 5px">抽检不合格</span>
            <span
              style="
                display: inline-block;
                height: 18px;
                width: 32px;
                border-radius: 15px;
                vertical-align: middle;
                background-color: #f58220;
              "
            ></span>
            <span style="font-size: 14px; margin-right: 5px">假冒伪劣</span>
            <span
              style="
                display: inline-block;
                height: 18px;
                width: 32px;
                border-radius: 15px;
                vertical-align: middle;
                background-color: #f56c6c;
              "
            ></span>
            <span style="font-size: 14px; margin-right: 5px"
              >新冠检测结果为阳性</span
            >
          </el-form-item>
        </el-form>
      </div>

      <div class="data_main">
        <div class="main_center fl">
          <div class="center_text" style="position: relative">
            <!--左上边框-->
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
            </div>
            <!--右上边框-->
            <div class="t_line_box">
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
            </div>
            <!--左下边框-->
            <div class="t_line_box">
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
            </div>
            <!--右下边框-->
            <div class="t_line_box">
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="main_title" style="width: 230px">
              <img src="./images/t_3.png" />
              <p style="text-align: center">地图</p>
            </div>
            <ve-bmap
              v-if="mapShow"
              ref="mapRef"
              height="100%"
              :settings="chartSettings"
              :series="chartSeries"
              :tooltip="chartTooltip"
              :events="chartEvents"
            >
              <div
                class="chain-block-box"
                v-show="sendData.length > 0 || receivedData.length > 0"
              >
                Hash值区块链验证:
                <span v-if="!hashFlag" class="hash-value" @click="verifyHash">
                  <i class="el-icon-thumb"></i>
                  {{ hashVal }}
                </span>
                <span v-else class="hash-result" @click="verifyHash"
                  >数据无篡改</span
                >
              </div>
              <div class="default-desc col-fff">默认显示最新50条收发货记录</div>
            </ve-bmap>
          </div>
        </div>

        <div class="main_right fr data_bottom">
          <div class="bottom_center fl">
            <div class="bottom_2 fl">
              <div class="t_line_box">
                <i class="t_l_line"></i>
                <i class="l_t_line"></i>
              </div>
              <div class="t_line_box">
                <i class="t_r_line"></i>
                <i class="r_t_line"></i>
              </div>
              <div class="t_line_box">
                <i class="l_b_line"></i>
                <i class="b_l_line"></i>
              </div>
              <div class="t_line_box">
                <i class="r_b_line"></i>
                <i class="b_r_line"></i>
              </div>
              <div class="main_title" style="width: 300px; left: 40%">
                <img src="./images/t_7.png" />
                产品追溯列表
              </div>
              <div class="main_table t_btn8 pr">
                <div style="color: white; display: flex; justify-content: end">
                  <div
                    @click="checkSend"
                    style="
                      padding: 0 20px;
                      border: 1px solid #2c58a6;
                      width: 120px;
                      margin-right: 5px;
                      border-radius: 5px;
                      background-color: #2c58a6;
                    "
                  >
                    收发货切换
                  </div>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th v-if="showSend">发货公司</th>
                      <th v-else>收货公司</th>
                      <th>商品名称</th>
                      <th v-if="showSend">发货日期</th>
                      <th v-else>收货日期</th>
                    </tr>
                  </thead>
                  <tbody v-if="showSend">
                    <tr
                      v-for="(item, index) in sendDataList"
                      :key="index"
                      @click="showGoodsInfo(item)"
                    >
                      <td>{{ item.companyName }}</td>
                      <td>{{ item.goodsName }}</td>
                      <td>
                        {{ item.shipTime ? item.shipTime.split("T")[0] : "" }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(item, index) in receiveDataList" :key="index">
                      <td>{{ item.companyName }}</td>
                      <td>{{ item.goodsName }}</td>
                      <td>
                        {{
                          item.receiveTime ? item.receiveTime.split("T")[0] : ""
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="paging pa">
                  <span class="disabled"> </span>
                  <span
                    :class="{ current: pageCur == item }"
                    v-for="(item, index) in pageList"
                    :key="index"
                    @click="pageChange(item)"
                    >{{ item }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="goodsInfoTitle"
      :visible.sync="goodsInfoVisible"
      width="55%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="12">
            <div>
              <div class="goods-info">
                <span class="text-content content-title">货物品码</span>
                <span class="text-content">{{ goodsInfo.gtin }}</span>
              </div>
              <div class="goods-info">
                <span class="text-content content-title">商品名称</span>
                <span class="text-content">{{ goodsInfo.goodsName }}</span>
              </div>
              <div class="goods-info">
                <span class="text-content content-title">录入批号</span>
                <span class="text-content">{{ goodsInfo.batchNumber }}</span>
              </div>
              <div class="goods-info">
                <span class="text-content content-title">生产厂商</span>
                <span class="text-content">{{ goodsInfo.manuName }}</span>
              </div>
              <div v-if="goodsInfo.originCountryName" class="goods-info">
                <span class="text-content content-title"> 原 产 国 </span>
                <span class="text-content">{{
                  goodsInfo.originCountryName
                }}</span>
              </div>
              <div class="goods-info">
                <span class="text-content content-title">商品规格</span>
                <span class="text-content">{{ goodsInfo.spec }}</span>
              </div>
              <!-- <div v-if="goodsInfo.ciq_code" class="goods-info">
                <span class="text-content content-title">检验检疫证</span>
                <span class="text-content">{{ goodsInfo.ciq_code }}</span>
              </div> -->
              <div v-if="goodsInfo.carNumber" class="goods-info">
                <span class="text-content content-title">车 牌 号</span>
                <!-- <span
                  class="text-content"
                  style="color: #409eff; cursor: pointer"
                  @click="handleCarInfo(goodsInfo.carNumber)"
                  >{{ goodsInfo.carNumber }}</span
                > -->
                <span class="text-content">{{ goodsInfo.carNumber }}</span>
              </div>
              <div v-if="goodsInfo.operator_name" class="goods-info">
                <span class="text-content content-title">操作人员</span>
                <span class="text-content">{{ goodsInfo.consigneeName }}</span>
              </div>
              <div v-if="goodsInfo.companyName && type == 1" class="goods-info">
                <span class="text-content content-title">发货公司</span>
                <span class="text-content">{{ goodsInfo.companyName }}</span>
              </div>
              <div
                v-if="goodsInfo.consigneeCompanyName && type == 1"
                class="goods-info"
              >
                <span class="text-content content-title">收货公司</span>
                <span class="text-content">{{
                  goodsInfo.consigneeCompanyName
                }}</span>
              </div>
              <div
                v-if="goodsInfo.consignerCompanyName && type == 2"
                class="goods-info"
              >
                <span class="text-content content-title">发货公司</span>
                <span class="text-content">{{
                  goodsInfo.consignerCompanyName
                }}</span>
              </div>
              <div v-if="goodsInfo.companyName && type == 2" class="goods-info">
                <span class="text-content content-title">收货公司</span>
                <span class="text-content">{{ goodsInfo.companyName }}</span>
              </div>
              <!-- <div class="goods-info">
                <span class="text-content content-title">发货统计</span>
                <span class="text-content">{{ goodsInfo.send_total }}</span>
              </div>
              <div v-if="goodsInfo.receive_total" class="goods-info">
                <span class="text-content content-title">收货统计</span>
                <span class="text-content">{{ goodsInfo.receive_total }}</span>
              </div> -->
              <div class="goods-info">
                <span class="text-content content-title">货品总数</span>
                <span class="text-content">{{ goodsInfo.goodsSum }}</span>
              </div>

              <div v-if="goodsInfo.shipTime" class="goods-info">
                <span class="text-content content-title">发货时间</span>
                <span class="text-content">{{ goodsInfo.shipTime }}</span>
              </div>
              <div v-if="goodsInfo.receiveTime" class="goods-info">
                <span class="text-content content-title">收货时间</span>
                <span class="text-content">{{ goodsInfo.receiveTime }}</span>
              </div>
              <div class="goods-info">
                <span class="text-content content-title">批次状态</span>
                <span class="text-content">
                  <template v-if="!statusMotifyFlag">
                    <el-tag
                      v-if="batchStatus"
                      :type="batchStatus | goodsTagFormat"
                      >{{ batchStatus | goodsStatusFormat }}
                    </el-tag>
                    <el-button type="text" @click="handleMotifyStatus"
                      >监管结论
                    </el-button>
                  </template>
                  <template v-else>
                    <el-select
                      size="small"
                      v-model="goodsInfo.conclusion"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-button
                      type="primary"
                      @click="handleSaveStatus(goodsInfo)"
                      size="medium"
                      style="height: 30px; line-height: 30px; width: 50px"
                      >保存
                    </el-button>
                  </template>
                </span>

                <el-popover
                  placement="top"
                  width="300"
                  v-model="popoverVisible"
                >
                  <div>
                    <el-radio-group v-model="notifyTarget" size="small">
                      <el-radio-button label="0">所有人员</el-radio-button>
                      <el-radio-button label="1">企业管理员</el-radio-button>
                      <el-radio-button label="2">监管人员</el-radio-button>
                    </el-radio-group>
                  </div>
                  <div style="margin-top: 10px; margin-bottom: 10px">
                    <el-radio-group v-model="notifyType" size="small">
                      <el-radio-button label="0">短信+微信</el-radio-button>
                      <el-radio-button label="1">短信</el-radio-button>
                      <el-radio-button label="2">微信</el-radio-button>
                    </el-radio-group>
                  </div>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="popoverVisible = false"
                      >取消</el-button
                    >
                    <el-button type="primary" size="mini" @click="sendNotify"
                      >确定发送</el-button
                    >
                  </div>
                  <el-button type="success" size="medium" slot="reference"
                    >发送通知
                  </el-button>
                </el-popover>

                <!-- <span class="text-content">{{goodsInfo.receive_time}}</span> -->
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <el-table
              :data="companyList"
              border
              fit
              @row-click="handleCompanyClick"
              class="goods-company-list"
            >
              <el-table-column prop="companyName" align="center">
                <template slot="header">
                  <div>
                    <span>此批次商品的销售公司列表</span>
                    <!--                    <el-button size="mini" type="success" @click="handleExport()">导出</el-button>-->
                  </div>
                </template>
              </el-table-column>
              <!--     <el-pagination
                           background
                           hide-on-single-page
                           :page-size="20"
                           @current-change="handleCurrentChangeSr"
                           :current-page="srlistPage"
                           layout="total, prev, pager, next, jumper"
                           :total="recordData.send_out_records_total"
                   ></el-pagination>-->
            </el-table>
          </el-col>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      title="此批次产品收发货记录"
      :visible.sync="recordDialogVisible"
      width="65%"
      append-to-body
    >
      <!-- <el-form inline :model="filterRecordForm">
        <el-form-item label="商品名称" style="margin-right:30px;">
          <el-input v-model="filterRecordForm.name_search"></el-input>
        </el-form-item>
        <el-form-item label="批号" style="margin-right:30px;">
          <el-input v-model="filterRecordForm.batch_search"></el-input>
        </el-form-item>
        <el-form-item label="序列号/条形码" style="margin-right:30px;">
          <el-input v-model="filterRecordForm.code_search"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleRecord">筛选</el-button>
        </el-form-item>
      </el-form>-->
      <el-tabs type="border-card">
        <el-tab-pane label="发货记录">
          <el-table
            border
            fit
            :data="recordData.send_out_records"
            empty-text="暂无数据"
            height="400"
            @sort-change="recordSortChange($event, 1)"
          >
            <el-table-column
              align="center"
              property="shipTime"
              label="发货时间"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsName"
              label="商品名称"
            ></el-table-column>
            <el-table-column align="center" label="货物品码">
              <template slot-scope="scope">
                <span>{{ scope.row.gtin }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="batchNumber"
              label="批号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consigneeCompanyName"
              label="收货方"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsSum"
              label="统计"
            ></el-table-column>
            <!-- <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status | tagFormat">{{
                  scope.row.status | statusFormat
                }}</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              label="区块链Hash值"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.txHash }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="carNumber"
              label="车牌号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consignerName"
              label="操作人员"
            ></el-table-column>
            <el-table-column
              align="center"
              property="remark"
              label="备注"
            ></el-table-column>
            <!-- <el-table-column align="center" label="操作" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleViewPic(scope.row)">查看图片</el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <!-- <div class="pagination-container">
            <el-pagination
              background
              hide-on-single-page
              :page-size="20"
              @current-change="handleCurrentChangeSr"
              :current-page="srlistPage"
              layout="total, prev, pager, next, jumper"
              :total="recordData.send_out_records_total"
            ></el-pagination>
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="收货记录">
          <el-table
            border
            fit
            :data="recordData.received_records"
            empty-text="暂无数据"
            height="400"
            @sort-change="recordSortChange($event, 2)"
          >
            <el-table-column
              align="center"
              property="receiveTime"
              label="收货时间"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsName"
              label="商品名称"
            ></el-table-column>
            <el-table-column align="center" label="货物品码">
              <template slot-scope="scope">
                <span>{{ scope.row.gtin }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="batchNumber"
              label="批号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consignerCompanyName"
              label="发货方"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsSum"
              label="统计"
            ></el-table-column>
            <el-table-column
              align="center"
              label="区块链Hash值"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>{{ scope.row.txHash }}</span>
                <!-- <span
                  v-if="!scope.row.hashFlag"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  style="cursor: pointer"
                  >{{ scope.row.hashVal }}</span
                >
                <span
                  v-else
                  style="color: #67c23a; cursor: pointer"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  >数据无篡改</span
                > -->
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="carNumber"
              label="车牌号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consigneeName"
              label="操作人员"
            ></el-table-column>
            <el-table-column
              align="center"
              property="remark"
              label="备注"
            ></el-table-column>
            <!-- <el-table-column align="center" label="操作" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleViewPic(scope.row)">查看图片</el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <!-- <div class="pagination-container">
            <el-pagination
              background
              hide-on-single-page
              :page-size="20"
              @current-change="handleCurrentChangeRr"
              :current-page="rrlistPage"
              layout="total, prev, pager, next, jumper"
              :total="recordData.received_records_total"
            ></el-pagination>
          </div> -->
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <div style="display: none">
      <!-- <custom-infowindow ref="customInfowindow"></custom-infowindow> -->
      <div ref="customInfowindow">
        <!-- <h4 style="margin:0 0 5px 0;padding:0.2em 0">公司名:{{companyInfo.name}}</h4>
        <div style="margin-top:5px">
          <i class="el-icon-location"></i>
          <span>地址:{{companyInfo.address}}</span>
        </div>-->
        <div style="margin-top: 10px">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-image
                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                  :preview-src-list="[
                    'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
                  ]"
                ></el-image>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-image
                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                ></el-image>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <el-image
                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                ></el-image>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-button
          style="margin-top: 10px"
          type="success"
          @click="handleClick"
          size="small"
          >详情
        </el-button>
      </div>
    </div>

    <el-dialog
      :title="dataType == 1 ? '发货记录' : '收货记录'"
      :visible.sync="dialogVisible"
      width="45%"
    >
      <el-table v-if="dataType == 1" border fit :data="gridData">
        <el-table-column
          align="center"
          property="created_at"
          label="发货日期"
        ></el-table-column>
        <el-table-column
          align="center"
          property="rc_name"
          label="收货公司"
        ></el-table-column>
        <el-table-column
          align="center"
          property="total"
          label="统计"
        ></el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status | tagFormat"
              >{{ scope.row.status | statusFormat }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleSup(scope)">监管</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else border fit :data="gridData">
        <el-table-column
          align="center"
          property="created_at"
          label="收货日期"
        ></el-table-column>
        <el-table-column
          align="center"
          property="soc_name"
          label="发货公司"
        ></el-table-column>
        <el-table-column
          align="center"
          property="total"
          label="统计"
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="handleSup(scope)" size="small"
              >监管
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="查看图片" :visible.sync="picDialogVisible" top="8vh">
      <div v-show="reportList.length > 0">
        <el-divider content-position="left">
          <el-tag>资证报告</el-tag>
        </el-divider>
        <el-row :gutter="10">
          <el-col :span="6" v-for="(item, index) in reportList" :key="index">
            <el-image
              style="height: 200px; width: 100%"
              :src="item"
              :preview-src-list="reportList"
            ></el-image>
          </el-col>
        </el-row>
      </div>
      <div v-show="attachList.length > 0">
        <el-divider content-position="left">
          <el-tag type="success">收发货实景</el-tag>
        </el-divider>
        <el-row :gutter="10">
          <el-col :span="6" v-for="(item, index) in attachList" :key="index">
            <el-image
              style="height: 200px; width: 100%"
              :src="item"
              :preview-src-list="attachList"
            ></el-image>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog
      title="车辆信息"
      :visible.sync="carInfoVisible"
      top="8vh"
      width="45%"
    >
      <el-form size="small" label-width="auto">
        <el-row :gutter="15">
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="车牌号"
                >{{ carInfo.car_number }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车牌颜色"
                >{{ carInfo.car_color }}
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="车辆吨位"
                >{{ carInfo.car_weight }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车辆经营范围"
                >{{ carInfo.car_dom }}
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="道路运输证号"
                >{{ carInfo.car_permit_code }}
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="业户名称"
                >{{ carInfo.company_name }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业户地址"
                >{{ carInfo.company_address }}
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="许可证号"
                >{{ carInfo.company_permit_code }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="许可证有效期起"
                >{{ carInfo.company_permit_start_date }}
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="许可证有效期止"
                >{{ carInfo.company_end_date }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业户经营范围"
                >{{ carInfo.company_dom }}
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
  <!--  <div style="text-align: left">-->
  <!--    <div style="margin: 10px">-->
  <!--      <el-form :inline="true">-->
  <!--        <el-form-item>-->
  <!--          <el-input-->
  <!--            v-model="gtin"-->
  <!--            placeholder="条形码/二维码值(查询时必填):"-->
  <!--            style="width: 300px"-->
  <!--          ></el-input>-->
  <!--        </el-form-item>-->
  <!--        <el-form-item label="">-->
  <!--          <el-input-->
  <!--            v-model="batchNumber"-->
  <!--            placeholder="批号(非必填):"-->
  <!--            style="width: 200px"-->
  <!--          ></el-input>-->
  <!--        </el-form-item>-->
  <!--        <el-form-item>-->
  <!--          <el-input-->
  <!--            v-model="quarantineCertNumber"-->
  <!--            placeholder="检验检疫证号(非必填):"-->
  <!--            style="width: 200px"-->
  <!--          ></el-input>-->
  <!--        </el-form-item>-->
  <!--        <el-form-item>-->
  <!--          <el-input-->
  <!--            v-model="carNumber"-->
  <!--            placeholder="车牌号(非必填):"-->
  <!--            style="width: 200px"-->
  <!--          ></el-input>-->
  <!--        </el-form-item>-->
  <!--        <el-form-item>-->
  <!--          <el-button type="primary" @click="handleSearch">查询</el-button>-->
  <!--          <el-button @click="handleRest">重置</el-button>-->
  <!--          &lt;!&ndash;          <el-button type="success" @click="handleExport">导出</el-button>&ndash;&gt;-->
  <!--        </el-form-item>-->
  <!--      </el-form>-->
  <!--      <div-->
  <!--        style="-->
  <!--          display: inline-block;-->
  <!--          margin-left: 100px;-->
  <!--          height: 25px;-->
  <!--          line-height: 25px;-->
  <!--        "-->
  <!--      >-->
  <!--        <span-->
  <!--          style="-->
  <!--            display: inline-block;-->
  <!--            height: 18px;-->
  <!--            width: 32px;-->
  <!--            border-radius: 15px;-->
  <!--            vertical-align: middle;-->
  <!--            background-color: #7ffd23;-->
  <!--          "-->
  <!--        ></span>-->
  <!--        <span style="font-size: 14px; margin-right: 5px">商品发货点</span>-->
  <!--        <span-->
  <!--          style="-->
  <!--            display: inline-block;-->
  <!--            height: 18px;-->
  <!--            width: 32px;-->
  <!--            border-radius: 15px;-->
  <!--            vertical-align: middle;-->
  <!--            background-color: #23e6fd;-->
  <!--          "-->
  <!--        ></span>-->
  <!--        <span style="font-size: 14px; margin-right: 5px">商品收货点</span>-->
  <!--        <span-->
  <!--          style="-->
  <!--            display: inline-block;-->
  <!--            height: 18px;-->
  <!--            width: 32px;-->
  <!--            border-radius: 15px;-->
  <!--            vertical-align: middle;-->
  <!--            background-color: #ed1941;-->
  <!--          "-->
  <!--        ></span>-->
  <!--        <span style="font-size: 14px; margin-right: 5px">抽检不合格</span>-->
  <!--        <span-->
  <!--          style="-->
  <!--            display: inline-block;-->
  <!--            height: 18px;-->
  <!--            width: 32px;-->
  <!--            border-radius: 15px;-->
  <!--            vertical-align: middle;-->
  <!--            background-color: #f58220;-->
  <!--          "-->
  <!--        ></span>-->
  <!--        <span style="font-size: 14px; margin-right: 5px">假冒伪劣</span>-->
  <!--        <span-->
  <!--          style="-->
  <!--            display: inline-block;-->
  <!--            height: 18px;-->
  <!--            width: 32px;-->
  <!--            border-radius: 15px;-->
  <!--            vertical-align: middle;-->
  <!--            background-color: #f56c6c;-->
  <!--          "-->
  <!--        ></span>-->
  <!--        <span style="font-size: 14px; margin-right: 5px"-->
  <!--          >新冠检测结果为阳性</span-->
  <!--        >-->
  <!--        &lt;!&ndash; <span-->
  <!--          style="-->
  <!--            display: inline-block;-->
  <!--            height: 18px;-->
  <!--            width: 32px;-->
  <!--            border-radius: 15px;-->
  <!--            vertical-align: middle;-->
  <!--            background-color: #7ffd23;-->
  <!--          "-->
  <!--        ></span>-->
  <!--        <span-->
  <!--          style="-->
  <!--            display: inline-block;-->
  <!--            height: 18px;-->
  <!--            width: 32px;-->
  <!--            border-radius: 15px;-->
  <!--            vertical-align: middle;-->
  <!--            background-color: #23e6fd;-->
  <!--          "-->
  <!--        ></span>-->
  <!--        <span style="font-size: 14px; margin-right: 5px"-->
  <!--          >新冠检测结果为阴性</span-->
  <!--        > &ndash;&gt;-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <ve-bmap-->
  <!--      v-if="mapShow"-->
  <!--      ref="mapRef"-->
  <!--      height="800px"-->
  <!--      :settings="chartSettings"-->
  <!--      :series="chartSeries"-->
  <!--      :tooltip="chartTooltip"-->
  <!--      :events="chartEvents"-->
  <!--    >-->
  <!--      <div-->
  <!--        class="chain-block-box"-->
  <!--        v-show="sendData.length > 0 || receivedData.length > 0"-->
  <!--      >-->
  <!--        Hash值区块链验证:-->
  <!--        <span v-if="!hashFlag" class="hash-value" @click="verifyHash">-->
  <!--          <i class="el-icon-thumb"></i>-->
  <!--          {{ hashVal }}-->
  <!--        </span>-->
  <!--        <span v-else class="hash-result" @click="verifyHash">数据无篡改</span>-->
  <!--      </div>-->
  <!--      <div class="default-desc">默认显示最新100条收发货记录</div>-->
  <!--    </ve-bmap>-->

  <!--    <el-dialog-->
  <!--      :title="goodsInfoTitle"-->
  <!--      :visible.sync="goodsInfoVisible"-->
  <!--      width="55%"-->
  <!--    >-->
  <!--      <el-row :gutter="20">-->
  <!--        <el-col :span="24">-->
  <!--          <el-col :span="12">-->
  <!--            <div>-->
  <!--              <div class="goods-info">-->
  <!--                <span class="text-content content-title">货物品码</span>-->
  <!--                <span class="text-content">{{ goodsInfo.gtin }}</span>-->
  <!--              </div>-->
  <!--              <div class="goods-info">-->
  <!--                <span class="text-content content-title">商品名称</span>-->
  <!--                <span class="text-content">{{ goodsInfo.goodsName }}</span>-->
  <!--              </div>-->
  <!--              <div class="goods-info">-->
  <!--                <span class="text-content content-title">录入批号</span>-->
  <!--                <span class="text-content">{{ goodsInfo.batchNumber }}</span>-->
  <!--              </div>-->
  <!--              <div class="goods-info">-->
  <!--                <span class="text-content content-title">生产厂商</span>-->
  <!--                <span class="text-content">{{ goodsInfo.manuName }}</span>-->
  <!--              </div>-->
  <!--              <div v-if="goodsInfo.originCountryName" class="goods-info">-->
  <!--                <span class="text-content content-title"> 原 产 国 </span>-->
  <!--                <span class="text-content">{{-->
  <!--                  goodsInfo.originCountryName-->
  <!--                }}</span>-->
  <!--              </div>-->
  <!--              <div class="goods-info">-->
  <!--                <span class="text-content content-title">商品规格</span>-->
  <!--                <span class="text-content">{{ goodsInfo.spec }}</span>-->
  <!--              </div>-->
  <!--              &lt;!&ndash; <div v-if="goodsInfo.ciq_code" class="goods-info">-->
  <!--                <span class="text-content content-title">检验检疫证</span>-->
  <!--                <span class="text-content">{{ goodsInfo.ciq_code }}</span>-->
  <!--              </div> &ndash;&gt;-->
  <!--              <div v-if="goodsInfo.carNumber" class="goods-info">-->
  <!--                <span class="text-content content-title">车 牌 号</span>-->
  <!--                &lt;!&ndash; <span-->
  <!--                  class="text-content"-->
  <!--                  style="color: #409eff; cursor: pointer"-->
  <!--                  @click="handleCarInfo(goodsInfo.carNumber)"-->
  <!--                  >{{ goodsInfo.carNumber }}</span-->
  <!--                > &ndash;&gt;-->
  <!--                <span>{{ goodsInfo.carNumber }}</span>-->
  <!--              </div>-->
  <!--              <div v-if="goodsInfo.operator_name" class="goods-info">-->
  <!--                <span class="text-content content-title">操作人员</span>-->
  <!--                <span class="text-content">{{ goodsInfo.consigneeName }}</span>-->
  <!--              </div>-->
  <!--              <div v-if="goodsInfo.companyName" class="goods-info">-->
  <!--                <span class="text-content content-title">发货公司</span>-->
  <!--                <span class="text-content">{{ goodsInfo.companyName }}</span>-->
  <!--              </div>-->
  <!--              <div v-if="goodsInfo.consignerCompanyName" class="goods-info">-->
  <!--                <span class="text-content content-title">收货公司</span>-->
  <!--                <span class="text-content">{{-->
  <!--                  goodsInfo.consignerCompanyName-->
  <!--                }}</span>-->
  <!--              </div>-->
  <!--              &lt;!&ndash; <div class="goods-info">-->
  <!--                <span class="text-content content-title">发货统计</span>-->
  <!--                <span class="text-content">{{ goodsInfo.send_total }}</span>-->
  <!--              </div>-->
  <!--              <div v-if="goodsInfo.receive_total" class="goods-info">-->
  <!--                <span class="text-content content-title">收货统计</span>-->
  <!--                <span class="text-content">{{ goodsInfo.receive_total }}</span>-->
  <!--              </div> &ndash;&gt;-->
  <!--              <div class="goods-info">-->
  <!--                <span class="text-content content-title">货品总数</span>-->
  <!--                <span class="text-content">{{ goodsInfo.goodsSum }}</span>-->
  <!--              </div>-->

  <!--              <div v-if="goodsInfo.shipTime" class="goods-info">-->
  <!--                <span class="text-content content-title">发货时间</span>-->
  <!--                <span class="text-content">{{ goodsInfo.shipTime }}</span>-->
  <!--              </div>-->
  <!--              <div v-if="goodsInfo.receiveTime" class="goods-info">-->
  <!--                <span class="text-content content-title">收货时间</span>-->
  <!--                <span class="text-content">{{ goodsInfo.receiveTime }}</span>-->
  <!--              </div>-->
  <!--              <div class="goods-info">-->
  <!--                <span class="text-content content-title">批次状态</span>-->
  <!--                <template v-if="!statusMotifyFlag">-->
  <!--                  <el-tag-->
  <!--                    v-if="batchStatus"-->
  <!--                    :type="batchStatus | goodsTagFormat"-->
  <!--                    >{{ batchStatus | goodsStatusFormat }}-->
  <!--                  </el-tag>-->
  <!--                  <el-button type="text" @click="handleMotifyStatus"-->
  <!--                    >监管结论-->
  <!--                  </el-button>-->
  <!--                </template>-->
  <!--                <template v-else>-->
  <!--                  <el-select-->
  <!--                    size="small"-->
  <!--                    v-model="goodsInfo.status"-->
  <!--                    placeholder="请选择"-->
  <!--                  >-->
  <!--                    <el-option-->
  <!--                      v-for="item in statusOptions"-->
  <!--                      :key="item.value"-->
  <!--                      :label="item.label"-->
  <!--                      :value="item.value"-->
  <!--                    >-->
  <!--                    </el-option>-->
  <!--                  </el-select>-->
  <!--                  <el-button-->
  <!--                    type="primary"-->
  <!--                    @click="handleSaveStatus"-->
  <!--                    size="mini"-->
  <!--                    >保存-->
  <!--                  </el-button>-->
  <!--                </template>-->
  <!--                <el-popover-->
  <!--                  placement="top"-->
  <!--                  width="300"-->
  <!--                  v-model="popoverVisible"-->
  <!--                >-->
  <!--                  <div>-->
  <!--                    <el-radio-group v-model="notifyTarget" size="small">-->
  <!--                      <el-radio-button label="0">所有人员</el-radio-button>-->
  <!--                      <el-radio-button label="1">企业管理员</el-radio-button>-->
  <!--                      <el-radio-button label="2">监管人员</el-radio-button>-->
  <!--                    </el-radio-group>-->
  <!--                  </div>-->
  <!--                  <div style="margin-top: 10px; margin-bottom: 10px">-->
  <!--                    <el-radio-group v-model="notifyType" size="small">-->
  <!--                      <el-radio-button label="0">短信+微信</el-radio-button>-->
  <!--                      <el-radio-button label="1">短信</el-radio-button>-->
  <!--                      <el-radio-button label="2">微信</el-radio-button>-->
  <!--                    </el-radio-group>-->
  <!--                  </div>-->
  <!--                  <div style="text-align: right; margin: 0">-->
  <!--                    <el-button-->
  <!--                      size="mini"-->
  <!--                      type="text"-->
  <!--                      @click="popoverVisible = false"-->
  <!--                      >取消</el-button-->
  <!--                    >-->
  <!--                    <el-button type="primary" size="mini" @click="sendNotify"-->
  <!--                      >确定发送</el-button-->
  <!--                    >-->
  <!--                  </div>-->
  <!--                  <el-button type="success" size="mini" slot="reference"-->
  <!--                    >发送通知-->
  <!--                  </el-button>-->
  <!--                </el-popover>-->

  <!--                &lt;!&ndash; <span class="text-content">{{goodsInfo.receive_time}}</span> &ndash;&gt;-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </el-col>-->
  <!--          <el-col :span="12">-->
  <!--            <el-table-->
  <!--              :data="companyList"-->
  <!--              border-->
  <!--              fit-->
  <!--              @row-click="handleCompanyClick"-->
  <!--              class="goods-company-list"-->
  <!--            >-->
  <!--              <el-table-column prop="companyName" align="center">-->
  <!--                <template slot="header">-->
  <!--                  <div>-->
  <!--                    <span>此批次商品的销售公司列表</span>-->
  <!--                    &lt;!&ndash;                    <el-button size="mini" type="success" @click="handleExport()">导出</el-button>&ndash;&gt;-->
  <!--                  </div>-->
  <!--                </template>-->
  <!--              </el-table-column>-->
  <!--         &lt;!&ndash;     <el-pagination-->
  <!--                      background-->
  <!--                      hide-on-single-page-->
  <!--                      :page-size="20"-->
  <!--                      @current-change="handleCurrentChangeSr"-->
  <!--                      :current-page="srlistPage"-->
  <!--                      layout="total, prev, pager, next, jumper"-->
  <!--                      :total="recordData.send_out_records_total"-->
  <!--              ></el-pagination>&ndash;&gt;-->
  <!--            </el-table>-->
  <!--          </el-col>-->
  <!--        </el-col>-->
  <!--      </el-row>-->
  <!--    </el-dialog>-->

  <!--    <el-dialog-->
  <!--      title="此批次产品收发货记录"-->
  <!--      :visible.sync="recordDialogVisible"-->
  <!--      width="65%"-->
  <!--      append-to-body-->
  <!--    >-->
  <!--      &lt;!&ndash; <el-form inline :model="filterRecordForm">-->
  <!--        <el-form-item label="商品名称" style="margin-right:30px;">-->
  <!--          <el-input v-model="filterRecordForm.name_search"></el-input>-->
  <!--        </el-form-item>-->
  <!--        <el-form-item label="批号" style="margin-right:30px;">-->
  <!--          <el-input v-model="filterRecordForm.batch_search"></el-input>-->
  <!--        </el-form-item>-->
  <!--        <el-form-item label="序列号/条形码" style="margin-right:30px;">-->
  <!--          <el-input v-model="filterRecordForm.code_search"></el-input>-->
  <!--        </el-form-item>-->
  <!--        <el-form-item>-->
  <!--          <el-button type="primary" @click="handleRecord">筛选</el-button>-->
  <!--        </el-form-item>-->
  <!--      </el-form>&ndash;&gt;-->
  <!--      <el-tabs type="border-card">-->
  <!--        <el-tab-pane label="发货记录">-->
  <!--          <el-table-->
  <!--            border-->
  <!--            fit-->
  <!--            :data="recordData.send_out_records"-->
  <!--            empty-text="暂无数据"-->
  <!--            height="400"-->
  <!--            @sort-change="recordSortChange($event, 1)"-->
  <!--          >-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="shipTime"-->
  <!--              label="发货时间"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="goodsName"-->
  <!--              label="商品名称"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column align="center" label="货物品码">-->
  <!--              <template slot-scope="scope">-->
  <!--                <span>{{ scope.row.gtin }}</span>-->
  <!--              </template>-->
  <!--            </el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="batchNumber"-->
  <!--              label="批号"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="consigneeCompanyName"-->
  <!--              label="收货方"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="goodsSum"-->
  <!--              label="统计"-->
  <!--            ></el-table-column>-->
  <!--            &lt;!&ndash; <el-table-column align="center" label="状态">-->
  <!--              <template slot-scope="scope">-->
  <!--                <el-tag :type="scope.row.status | tagFormat">{{-->
  <!--                  scope.row.status | statusFormat-->
  <!--                }}</el-tag>-->
  <!--              </template>-->
  <!--            </el-table-column> &ndash;&gt;-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              label="区块链Hash值"-->
  <!--              show-overflow-tooltip-->
  <!--            >-->
  <!--              <template slot-scope="scope">-->
  <!--                <span>{{ scope.row.txHash }}</span>-->
  <!--              </template>-->
  <!--            </el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="carNumber"-->
  <!--              label="车牌号"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="consignerName"-->
  <!--              label="操作人员"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="remark"-->
  <!--              label="备注"-->
  <!--            ></el-table-column>-->
  <!--            &lt;!&ndash; <el-table-column align="center" label="操作" show-overflow-tooltip>-->
  <!--              <template slot-scope="scope">-->
  <!--                <el-button size="mini" type="primary" @click="handleViewPic(scope.row)">查看图片</el-button>-->
  <!--              </template>-->
  <!--            </el-table-column> &ndash;&gt;-->
  <!--          </el-table>-->
  <!--          &lt;!&ndash; <div class="pagination-container">-->
  <!--            <el-pagination-->
  <!--              background-->
  <!--              hide-on-single-page-->
  <!--              :page-size="20"-->
  <!--              @current-change="handleCurrentChangeSr"-->
  <!--              :current-page="srlistPage"-->
  <!--              layout="total, prev, pager, next, jumper"-->
  <!--              :total="recordData.send_out_records_total"-->
  <!--            ></el-pagination>-->
  <!--          </div> &ndash;&gt;-->
  <!--        </el-tab-pane>-->
  <!--        <el-tab-pane label="收货记录">-->
  <!--          <el-table-->
  <!--            border-->
  <!--            fit-->
  <!--            :data="recordData.received_records"-->
  <!--            empty-text="暂无数据"-->
  <!--            height="400"-->
  <!--            @sort-change="recordSortChange($event, 2)"-->
  <!--          >-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="receiveTime"-->
  <!--              label="收货时间"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="goodsName"-->
  <!--              label="商品名称"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column align="center" label="货物品码">-->
  <!--              <template slot-scope="scope">-->
  <!--                <span>{{ scope.row.gtin }}</span>-->
  <!--              </template>-->
  <!--            </el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="batchNumber"-->
  <!--              label="批号"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="consignerCompanyName"-->
  <!--              label="发货方"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="goodsSum"-->
  <!--              label="统计"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              label="区块链Hash值"-->
  <!--              show-overflow-tooltip-->
  <!--            >-->
  <!--              <template slot-scope="scope">-->
  <!--                <span>{{ scope.row.txHash }}</span>-->
  <!--                &lt;!&ndash; <span-->
  <!--                  v-if="!scope.row.hashFlag"-->
  <!--                  @click="scope.row.hashFlag = !scope.row.hashFlag"-->
  <!--                  style="cursor: pointer"-->
  <!--                  >{{ scope.row.hashVal }}</span-->
  <!--                >-->
  <!--                <span-->
  <!--                  v-else-->
  <!--                  style="color: #67c23a; cursor: pointer"-->
  <!--                  @click="scope.row.hashFlag = !scope.row.hashFlag"-->
  <!--                  >数据无篡改</span-->
  <!--                > &ndash;&gt;-->
  <!--              </template>-->
  <!--            </el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="carNumber"-->
  <!--              label="车牌号"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="consigneeName"-->
  <!--              label="操作人员"-->
  <!--            ></el-table-column>-->
  <!--            <el-table-column-->
  <!--              align="center"-->
  <!--              property="remark"-->
  <!--              label="备注"-->
  <!--            ></el-table-column>-->
  <!--            &lt;!&ndash; <el-table-column align="center" label="操作" show-overflow-tooltip>-->
  <!--              <template slot-scope="scope">-->
  <!--                <el-button size="mini" type="primary" @click="handleViewPic(scope.row)">查看图片</el-button>-->
  <!--              </template>-->
  <!--            </el-table-column> &ndash;&gt;-->
  <!--          </el-table>-->
  <!--          &lt;!&ndash; <div class="pagination-container">-->
  <!--            <el-pagination-->
  <!--              background-->
  <!--              hide-on-single-page-->
  <!--              :page-size="20"-->
  <!--              @current-change="handleCurrentChangeRr"-->
  <!--              :current-page="rrlistPage"-->
  <!--              layout="total, prev, pager, next, jumper"-->
  <!--              :total="recordData.received_records_total"-->
  <!--            ></el-pagination>-->
  <!--          </div> &ndash;&gt;-->
  <!--        </el-tab-pane>-->
  <!--      </el-tabs>-->
  <!--    </el-dialog>-->

  <!--    <div style="display: none">-->
  <!--      &lt;!&ndash; <custom-infowindow ref="customInfowindow"></custom-infowindow> &ndash;&gt;-->
  <!--      <div ref="customInfowindow">-->
  <!--        &lt;!&ndash; <h4 style="margin:0 0 5px 0;padding:0.2em 0">公司名:{{companyInfo.name}}</h4>-->
  <!--        <div style="margin-top:5px">-->
  <!--          <i class="el-icon-location"></i>-->
  <!--          <span>地址:{{companyInfo.address}}</span>-->
  <!--        </div>&ndash;&gt;-->
  <!--        <div style="margin-top: 10px">-->
  <!--          <el-row :gutter="20">-->
  <!--            <el-col :span="8">-->
  <!--              <div class="grid-content bg-purple">-->
  <!--                <el-image-->
  <!--                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"-->
  <!--                  :preview-src-list="[-->
  <!--                    'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',-->
  <!--                  ]"-->
  <!--                ></el-image>-->
  <!--              </div>-->
  <!--            </el-col>-->
  <!--            <el-col :span="8">-->
  <!--              <div class="grid-content bg-purple">-->
  <!--                <el-image-->
  <!--                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"-->
  <!--                ></el-image>-->
  <!--              </div>-->
  <!--            </el-col>-->
  <!--            <el-col :span="8">-->
  <!--              <div class="grid-content bg-purple">-->
  <!--                <el-image-->
  <!--                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"-->
  <!--                ></el-image>-->
  <!--              </div>-->
  <!--            </el-col>-->
  <!--          </el-row>-->
  <!--        </div>-->
  <!--        <el-button-->
  <!--          style="margin-top: 10px"-->
  <!--          type="success"-->
  <!--          @click="handleClick"-->
  <!--          size="small"-->
  <!--          >详情-->
  <!--        </el-button>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <el-dialog-->
  <!--      :title="dataType == 1 ? '发货记录' : '收货记录'"-->
  <!--      :visible.sync="dialogVisible"-->
  <!--      width="45%"-->
  <!--    >-->
  <!--      <el-table v-if="dataType == 1" border fit :data="gridData">-->
  <!--        <el-table-column-->
  <!--          align="center"-->
  <!--          property="created_at"-->
  <!--          label="发货日期"-->
  <!--        ></el-table-column>-->
  <!--        <el-table-column-->
  <!--          align="center"-->
  <!--          property="rc_name"-->
  <!--          label="收货公司"-->
  <!--        ></el-table-column>-->
  <!--        <el-table-column-->
  <!--          align="center"-->
  <!--          property="total"-->
  <!--          label="统计"-->
  <!--        ></el-table-column>-->
  <!--        <el-table-column align="center" label="状态">-->
  <!--          <template slot-scope="scope">-->
  <!--            <el-tag :type="scope.row.status | tagFormat"-->
  <!--              >{{ scope.row.status | statusFormat }}-->
  <!--            </el-tag>-->
  <!--          </template>-->
  <!--        </el-table-column>-->
  <!--        <el-table-column align="center" label="操作">-->
  <!--          <template slot-scope="scope">-->
  <!--            <el-button type="primary" @click="handleSup(scope)">监管</el-button>-->
  <!--          </template>-->
  <!--        </el-table-column>-->
  <!--      </el-table>-->
  <!--      <el-table v-else border fit :data="gridData">-->
  <!--        <el-table-column-->
  <!--          align="center"-->
  <!--          property="created_at"-->
  <!--          label="收货日期"-->
  <!--        ></el-table-column>-->
  <!--        <el-table-column-->
  <!--          align="center"-->
  <!--          property="soc_name"-->
  <!--          label="发货公司"-->
  <!--        ></el-table-column>-->
  <!--        <el-table-column-->
  <!--          align="center"-->
  <!--          property="total"-->
  <!--          label="统计"-->
  <!--        ></el-table-column>-->
  <!--        <el-table-column align="center" label="操作">-->
  <!--          <template slot-scope="scope">-->
  <!--            <el-button type="primary" @click="handleSup(scope)" size="small"-->
  <!--              >监管-->
  <!--            </el-button>-->
  <!--          </template>-->
  <!--        </el-table-column>-->
  <!--      </el-table>-->
  <!--    </el-dialog>-->

  <!--    <el-dialog title="查看图片" :visible.sync="picDialogVisible" top="8vh">-->
  <!--      <div v-show="reportList.length > 0">-->
  <!--        <el-divider content-position="left">-->
  <!--          <el-tag>资证报告</el-tag>-->
  <!--        </el-divider>-->
  <!--        <el-row :gutter="10">-->
  <!--          <el-col :span="6" v-for="(item, index) in reportList" :key="index">-->
  <!--            <el-image-->
  <!--              style="height: 200px; width: 100%"-->
  <!--              :src="item"-->
  <!--              :preview-src-list="reportList"-->
  <!--            ></el-image>-->
  <!--          </el-col>-->
  <!--        </el-row>-->
  <!--      </div>-->
  <!--      <div v-show="attachList.length > 0">-->
  <!--        <el-divider content-position="left">-->
  <!--          <el-tag type="success">收发货实景</el-tag>-->
  <!--        </el-divider>-->
  <!--        <el-row :gutter="10">-->
  <!--          <el-col :span="6" v-for="(item, index) in attachList" :key="index">-->
  <!--            <el-image-->
  <!--              style="height: 200px; width: 100%"-->
  <!--              :src="item"-->
  <!--              :preview-src-list="attachList"-->
  <!--            ></el-image>-->
  <!--          </el-col>-->
  <!--        </el-row>-->
  <!--      </div>-->
  <!--    </el-dialog>-->

  <!--    <el-dialog-->
  <!--      title="车辆信息"-->
  <!--      :visible.sync="carInfoVisible"-->
  <!--      top="8vh"-->
  <!--      width="45%"-->
  <!--    >-->
  <!--      <el-form size="small" label-width="auto">-->
  <!--        <el-row :gutter="15">-->
  <!--          <el-col :span="24">-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="车牌号"-->
  <!--                >{{ carInfo.car_number }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="车牌颜色"-->
  <!--                >{{ carInfo.car_color }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--          </el-col>-->
  <!--          <el-col :span="24">-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="车辆吨位"-->
  <!--                >{{ carInfo.car_weight }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="车辆经营范围"-->
  <!--                >{{ carInfo.car_dom }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--          </el-col>-->
  <!--          <el-col :span="24">-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="道路运输证号"-->
  <!--                >{{ carInfo.car_permit_code }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--          </el-col>-->
  <!--          <el-col :span="24">-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="业户名称"-->
  <!--                >{{ carInfo.company_name }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="业户地址"-->
  <!--                >{{ carInfo.company_address }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--          </el-col>-->
  <!--          <el-col :span="24">-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="许可证号"-->
  <!--                >{{ carInfo.company_permit_code }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="许可证有效期起"-->
  <!--                >{{ carInfo.company_permit_start_date }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--          </el-col>-->
  <!--          <el-col :span="24">-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="许可证有效期止"-->
  <!--                >{{ carInfo.company_end_date }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--            <el-col :span="12">-->
  <!--              <el-form-item label="业户经营范围"-->
  <!--                >{{ carInfo.company_dom }}-->
  <!--              </el-form-item>-->
  <!--            </el-col>-->
  <!--          </el-col>-->
  <!--        </el-row>-->
  <!--      </el-form>-->
  <!--    </el-dialog>-->

  <!--    <el-image-viewer-->
  <!--      style="z-index: 9999"-->
  <!--      v-if="showViewer"-->
  <!--      :on-close="closeViewer"-->
  <!--      :url-list="[url]"-->
  <!--    />-->
  <!--  </div>-->
</template>

<script>
import { mapGetters } from "vuex";
import { createHash, local } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
//import { mapConfig1 } from "./custom_map_config.js";
import { mapConfig } from "./custom_map_config.js";
import axios from "@/util/api";

// import excel from "@/api/excel";
// import department from "@/api/department.js";
// import goods from "@/api/goods.js";
// import company from "@/api/company.js";
// import input_log from "@/api/input_log.js";
// import jg_car from "@/api/jg_car.js";

export default {
  components: {
    //ElImageViewer,
  },
  data() {
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        if (params.seriesType === "effectScatter") {
          return `${params.value[7]}<br>${params.marker}${
            params.value[4] || params.value[9]
          }-${params.value[5]}-${params.value[6]}`;
        }
      },
    };
    const _this = this;
    this.chartEvents = {
      click: (v) => {
        //console.log("点击",v)
        this.id = v.value[2];
        this.type = v.value[8];
        this.batchStatus = v.value[10];
        // console.log("value",v.value)
        this.$extraApi
          .get(`/v1/pc/trace/detail`, {
            id: v.value[2],
            opt: v.value[8],
            page: 0,
          })
          .then((response) => {
            let res = response.data;
            _this.statusMotifyFlag = false;
            _this.goodsInfo = res.receipt ? res.receipt : res.shipping;
            if (_this.goodsInfo.gtinInfo) {
              let gtinInfo = JSON.parse(_this.goodsInfo.gtinInfo);
              _this.goodsInfo.manuName = gtinInfo.manuName;
              _this.goodsInfo.spec = gtinInfo.spec;
              _this.goodsInfo.originCountryName = gtinInfo.originCountryName;
            }
            _this.companyList = res.companys;
            _this.goodsInfoVisible = true;
          });
      },
      finished: (_) => {
        // _this.getGeo();
      },
    };
    return {
      page: 1,
      sendDataList: [],
      receiveDataList: [],
      showSend: true,
      batchStatus: null,
      companyId: null,
      dataType: null,
      gtin: "",
      batchNumber: "",
      quarantineCertNumber: "",
      carNumber: "",
      goodsInfoTitle: "",
      chartSeries: [],
      gridData: [],
      companyList: [],
      pointList: [],
      sendData: [],
      receivedData: [],
      goodsInfo: {},
      carInfo: {},
      id: "",
      srlistPage: 1,
      rrlistPage: 1,
      type: "",
      dialogVisible: false,
      goodsInfoVisible: false,
      statusMotifyFlag: false,
      carInfoVisible: false,
      mapShow: false,
      searchFlag: false,
      currentLng: null,
      currentLat: null,
      chartSettings: {
        key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
        bmap: {
          center: [120, 30],
          zoom: 5,
          roam: true,
          enableMapClick: false,
          mapStyleV2: { styleJson: mapConfig },
        },
      },
      statusOptions: [
        {
          value: "normal",
          label: "正常",
        },
        {
          value: "unqualified",
          label: "抽检不合格",
        },
        {
          value: "fake",
          label: "假冒伪劣",
        },
        {
          value: "ncov_positive",
          label: "新冠检测结果为阳性",
        },
        {
          value: "ncov_negative",
          label: "新冠检测结果为阴性",
        },
      ],
      recordDialogVisible: false,
      recordData: {},
      total: "",
      hashFlag: false,
      hashVal: createHash(30),
      picDialogVisible: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      attachList: [],
      reportList: [],
      url: "",
      showViewer: false,
      popoverVisible: false,
      notifyTarget: "0",
      notifyType: "0",
      pageList: [1, 2, 3, 4],
      pageCur: 1,
    };
  },
  computed: {
    // ...mapGetters(["province", "city", "district"]),
  },
  created() {},
  filters: {
    tagFormat(status) {
      const tagMap = {
        0: "primary",
        1: "success",
      };
      return tagMap[status];
    },
    statusFormat(status) {
      const statusMap = {
        0: "未收货",
        1: "已收货",
      };
      return statusMap[status];
    },
    goodsTagFormat(status) {
      const tagMap = {
        normal: "success",
        unqualified: "danger",
        fake: "warning",
        ncov_positive: "danger",
        ncov_negative: "success",
      };
      return tagMap[status];
    },
    goodsStatusFormat(status) {
      const statusMap = {
        normal: "正常",
        unqualified: "抽检不合格",
        fake: "假冒伪劣",
        ncov_positive: "新冠检测结果为阳性",
        ncov_negative: "新冠检测结果为阴性",
      };
      return statusMap[status];
    },
  },
  mounted() {
    this.initMap({ opt: 1 });
    // this.initInput(1);
    // this.initInput(2);
  },
  methods: {
    goodsTagFormatMethode(status) {
      const tagMap = {
        normal: 0,
        unqualified: 1,
        fake: 2,
        ncov_positive: 3,
        ncov_negative: 4,
      };
      return tagMap[status];
    },
    checkSend() {
      this.showSend = !this.showSend;
      this.pageCur = 1;
      this.pageList = [];
      if (this.showSend) {
        for (let i = 0; i < this.sendData.length / 16; i++) {
          this.pageList.push(i + 1);
        }
      } else {
        for (let i = 0; i < this.receivedData.length / 16; i++) {
          this.pageList.push(i + 1);
        }
      }
    },
    // page change
    pageChange(page) {
      this.pageCur = page;
      if (this.showSend)
        this.sendDataList = this.pagination(16, page, this.sendData);
      else this.receiveDataList = this.pagination(16, page, this.receivedData);
    },
    //show goodsInfoVisible
    showGoodsInfo(e) {
      const _this = this;
      this.id = e.id;
      this.type = e.goodsUnit;
      this.batchStatus = e.conclusion;
      //console.log("item",e)
      this.$extraApi
        .get(`/v1/pc/trace/detail`, { id: e.id, page: 0 })
        .then((response) => {
          let res = response.data;
          _this.statusMotifyFlag = false;
          _this.goodsInfo = res.receipt ? res.receipt : res.shipping;
          if (_this.goodsInfo.gtinInfo) {
            let gtinInfo = JSON.parse(_this.goodsInfo.gtinInfo);
            _this.goodsInfo.manuName = gtinInfo.manuName;
            _this.goodsInfo.spec = gtinInfo.spec;
            _this.goodsInfo.originCountryName = gtinInfo.originCountryName;
          }
          _this.companyList = res.companys;
          _this.goodsInfoVisible = true;
        });
    },

    pagination(pageSize, currentPage, arr) {
      var skipNum = (currentPage - 1) * pageSize;
      var newArr =
        skipNum + pageSize >= arr.length
          ? arr.slice(skipNum, arr.length)
          : arr.slice(skipNum, skipNum + pageSize);
      return newArr;
    },
    sendNotify() {
      const { role_id } = local.get("user");

      axios
        .get("/pc/role/single", { role_id: role_id + "" })
        .then((response) => {
          const menu_list = response.data.role.menu_list;
          let hasRole = menu_list.find((item) => item.menu_id == 89);
          if (hasRole) {
            let hasPermission = hasRole.menu_btns_list.find(
              (item) => item == 1
            );
            if (hasPermission) {
              this.popoverVisible = false;
              let params = {
                batchNumber: this.goodsInfo.batchNumber,
                gtin: this.goodsInfo.gtin,
                target: this.notifyTarget,
                type: this.notifyType,
              };
              this.$extraApi
                .post(`/v1/pc/notice/notify`, { noticeSendDto: params })
                .then((response) => {
                  this.$notify({
                    title: "成功",
                    message: "信息已发送",
                    type: "success",
                  });
                })
                .catch((e) => {
                  this.$notify({
                    title: "失败",
                    message: "信息发送失败",
                    type: "warning",
                  });
                });
            } else {
              this.$notify({
                title: "无权限",
                message: "所在用户组无权限发送消息",
                type: "warning",
              });
            }
          }
        });
      // let company_ids = [];
      // this.companyList.forEach((item) => {
      //   company_ids.push(item.id);
      // });
      // department
      //   .notify_company({
      //     gb_id: this.goodsInfo.gb_id,
      //     company_ids: company_ids,
      //     type: this.goodsInfo.status,
      //   })
      //   .then((response) => {
      //     this.$message({
      //       message: "发送成功",
      //       type: "success",
      //     });
      //   });
    },
    initMap(params) {
      const loading = this.$loading({
        target: document.querySelector(".container"),
        lock: true,
        text: "数据加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //console.log("chartSettings:",this.chartSettings)
      this.mapShow = false;
      this.chartSeries = [];
      const sendColors = [
        "#7ffd23",
        "#ed1941",
        "#f58220",
        "#F56C6C",
        "#7ffd23",
      ];
      // "#33a3dc",#19d4ae
      const receivedColors = [
        "#23e6fd",
        "#ed1941",
        "#f58220",
        "#F56C6C",
        "#23e6fd",
      ];
      let data = {
        ...params,
      };
      // console.log('触发',local.get("user"),local.get("company_id"));
      const { user_type } = local.get("user");
      if (user_type === 4) {
        data.companyId = local.get("company_id");
      }
      this.$extraApi.get(`/v1/pc/trace/goods`, data).then((response) => {
        //console.log("response: ", response);
        let res = response.data;
        let sendData = [];
        let receiveData = [];
        let pointList = [];
        if (res.shippingList.length > 0) {
          sendData = this.convertData(res.shippingList, 1);
          this.sendData = res.shippingList;
          res.shippingList.forEach((item) => {
            let location = item.consignerCoordinate;
            if (location) {
              let locationArr = location.split(",");
              pointList.push({
                longitude: locationArr[0],
                latitude: locationArr[1],
              });
            }
          });
        }
        if (res.receiptList.length > 0) {
          receiveData = this.convertData(res.receiptList, 2);
          this.receivedData = res.receiptList;
          res.receiptList.forEach((item) => {
            let location = item.consigneeCoordinate;
            if (location) {
              let locationArr = location.split(",");
              pointList.push({
                longitude: locationArr[0],
                latitude: locationArr[1],
              });
            }
          });
        }
        let arr = [];
        if (res.shippingList.length > 0 || res.receiptList.length > 0) {
          sendData.forEach((item, index) => {
            console.log(
              receivedColors[this.goodsTagFormatMethode(item[0][10])]
            );
            arr.push({
              type: "effectScatter",
              coordinateSystem: "bmap",
              zlevel: 2,
              rippleEffect: {
                //涟漪特效
                period: 4, //动画时间，值越小速度越快
                brushType: "stroke", //波纹绘制方式 stroke, fill
                scale: 4, //波纹圆环最大限制，值越大波纹越大
              },
              symbol: "circle",
              itemStyle: {
                normal: {
                  // color: sendColors[item[0][3]],
                  color: sendColors[this.goodsTagFormatMethode(item[0][10])],
                },
              },
              data: item,
            });
          });
          receiveData.forEach((item, index) => {
            arr.push({
              type: "effectScatter",
              coordinateSystem: "bmap",
              zlevel: 2,
              rippleEffect: {
                //涟漪特效
                period: 4, //动画时间，值越小速度越快
                brushType: "stroke", //波纹绘制方式 stroke, fill
                scale: 4, //波纹圆环最大限制，值越大波纹越大
              },
              symbol: "circle",
              itemStyle: {
                normal: {
                  // color: receivedColors[item[0][3]],
                  color:
                    receivedColors[this.goodsTagFormatMethode(item[0][10])],
                },
              },
              data: item,
            });
          });
          this.chartSeries = arr;
        } else {
          this.$message({
            message: "无产品收发货记录",
            type: "warning",
          });
          return;
        }
        this.mapShow = true;
        this.pointList = pointList;
        this.sendDataList = this.pagination(16, this.page, this.sendData);
        this.receiveDataList = this.pagination(
          16,
          this.page,
          this.receivedData
        );
        this.pageList = [];
        for (let i = 0; i < sendData.length / 16; i++) {
          this.pageList.push(i + 1);
        }
        this.$nextTick((_) => {
          this.getGeo();
        });
        loading.close();
      });
    },
    initInput(type) {
      switch (type) {
        case 1:
          input_log.query({ opt: 1 }).then((response) => {
            const { data } = response;
            if (data && data.value) {
              this.gtin = data.value;
            }
          });
          break;
        case 2:
          input_log.query({ opt: 2 }).then((response) => {
            const { data } = response;
            if (data && data.value) {
              this.batchNumber = data.value;
            }
          });
          break;
      }
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    convertData(data, type) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let location =
          type == 1 ? data[i].consignerCoordinate : data[i].consigneeCoordinate;
        if (location) {
          let locationArr = location.split(",");
          let longitude = locationArr[0];
          let latitude = locationArr[1];
          let companyName = data[i].companyName;
          let formattedData = [
            [
              longitude,
              latitude,
              data[i].id,
              data[i].status,
              data[i].gtin,
              data[i].batchNumber,
              data[i].goodsName,
              companyName || "无",
              type,
              data[i].gtin,
              data[i].conclusion,
            ],
          ];
          result.push(formattedData);
        }
      }
      return result;
    },
    handleClick() {
      company
        .get(this.companyId, { opt: 3, type: this.dataType })
        .then((response) => {
          let res = response.data;
          this.dialogVisible = true;
        });
    },
    handleSup(row) {},
    handleMotifyStatus() {
      this.statusMotifyFlag = true;
    },
    // handleSaveStatus() {
    //   department
    //     .sup_goods({
    //       gb_id: this.goodsInfo.gb_id,
    //       status: this.goodsInfo.status,
    //       opt: 1,
    //     })
    //     .then((response) => {
    //       let params = { opt: 1 };
    //       if (this.gtin && this.searchFlag) {
    //         params = { opt: 1, gtin: this.gtin, batchNumber: this.batchNumber };
    //       }
    //       this.initMap(params);
    //       this.statusMotifyFlag = false;
    //     });
    // },
    handleSaveStatus(item) {
      this.$extraApi
        .post("/v1/pc/trace/update", {
          gb_id: this.goodsInfo.gb_id,
          conclusion: this.goodsInfo.conclusion,
          opt: 1,
          gtin: item.gtin,
          batchNumber: item.batchNumber,
        })
        .then((response) => {
          let params = { opt: 1 };
          if (this.gtin && this.searchFlag) {
            params = { opt: 1, gtin: this.gtin, batchNumber: this.batchNumber };
          }
          this.batchStatus = this.goodsInfo.conclusion;
          this.initMap(params);
          this.statusMotifyFlag = false;
        });
    },
    handleSearch() {
      if (this.gtin || this.quarantineCertNumber || this.carNumber) {
        this.searchFlag = true;
        this.pageCur = 1;
        this.initMap({
          gtin: this.gtin,
          batchNumber: this.batchNumber,
          quarantineCertNumber: this.quarantineCertNumber,
          carNumber: this.carNumber,
        });
      } else {
        this.$message({
          showClose: true,
          message: "条形码或检验检疫证号或车牌号必填其中一项",
          type: "warning",
        });
      }
    },
    handleRest() {
      this.gtin = "";
      this.batchNumber = "";
      this.quarantineCertNumber = "";
      this.carNumber = "";
      this.searchFlag = false;
      this.initMap({ opt: 3 });
    },
    async handleCompanyClick(row, column, event) {
      this.companyId = row.companyId;
      this.srlistPage = 1;
      this.rrlistPage = 1;
      await Promise.all([this.fetchSendRecords(), this.fetchReceiveRecords()]);
      this.recordDialogVisible = true;
    },
    async fetchSendRecords() {
      await this.$extraApi
        .get(`/v1/pc/trace/company`, {
          companyId: this.companyId,
          gtin: this.goodsInfo.gtin,
          batchNumber: this.goodsInfo.batchNumber,
          type: 1,
          page: this.srlistPage,
        })
        .then((response) => {
          //console.log("response: ", response);
          let res = response.data;
          this.$set(this.recordData, "send_out_records", res.shippingList);
          // this.$set(this.recordData, "send_out_records_total", res.total);
        });
    },
    async fetchReceiveRecords() {
      await this.$extraApi
        .get(`/v1/pc/trace/company`, {
          companyId: this.companyId,
          gtin: this.goodsInfo.gtin,
          batchNumber: this.goodsInfo.batchNumber,
          type: 2,
          page: this.srlistPage,
        })
        .then((response) => {
          let res = response.data;
          this.$set(this.recordData, "received_records", res.receiptList);
          // this.$set(this.recordData, "received_records_total", res.total);
        });
    },
    handleCurrentChangeSr(val) {
      this.srlistPage = val;
      this.fetchSendRecords();
    },
    handleCurrentChangeRr(val) {
      this.rrlistPage = val;
      this.fetchReceiveRecords();
    },
    getGeo() {
      setTimeout((_) => {
        let pointArr = [];
        this.pointList.forEach((item) => {
          pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
        });
        let echarts = this.$refs.mapRef.echarts;
        let map = echarts.getModel().getComponent("bmap").getBMap();
        let view = map.getViewport(eval(pointArr));
        let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
        let centerPoint = view.center;
        map.centerAndZoom(centerPoint, mapZoom);
      }, 200);
    },
    handleExport() {},
    download(res) {
      if (!res) {
        return;
      }
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        })
      );
      let fname = "产品追溯详情表.xlsx";
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fname);
      document.body.appendChild(link);
      link.click();
    },
    verifyHash() {
      this.hashFlag = !this.hashFlag;
    },
    handleViewPic(row) {
      this.attachList = [];
      this.reportList = [];
      const { attaches, goods_batch } = row;
      if (attaches.length > 0) {
        this.attachList = attaches.map((item) => this.baseUrl + item.file);
      }
      if (goods_batch && goods_batch.goods_reports.length > 0) {
        this.reportList = goods_batch.goods_reports.map(
          (item) => this.baseUrl + item.file
        );
      }
      if (
        attaches.length > 0 ||
        (goods_batch && goods_batch.goods_reports.length > 0)
      ) {
        this.picDialogVisible = true;
      } else {
        this.$message({
          message: "无图片",
          type: "warning",
        });
      }
    },
    handleViewPic1(path) {
      this.url = this.baseUrl + path;
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleCarInfo(carNumber) {
      jg_car.get_car({ carNumber: carNumber }).then((response) => {
        if (response.data) {
          this.carInfo = response.data;
          this.carInfoVisible = true;
        } else {
          this.$message({
            message: "暂无此车辆详细信息",
            type: "warning",
          });
        }
      });
    },
    // getGeo() {
    //   let echarts = this.$refs.mapRef.echarts;
    //   let map = echarts
    //     .getModel()
    //     .getComponent("bmap")
    //     .getBMap();
    //   let myGeo = new window.BMap.Geocoder();
    //   // 将地址解析结果显示在地图上，并调整地图视野
    //   myGeo.getPoint(
    //     this.province + this.city + this.district,
    //     function(point) {
    //       if (point) {
    //         map.centerAndZoom(point, 14);
    //       }
    //     },
    //     this.city
    //   );
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "css/animsition.css";
@import "css/reset.css";
@import "css/scanboard.css";
.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}
.goods-info {
  border: 1px solid #ebeef5;
  border-collapse: separate;
  text-align: left;
}

.text-content {
  padding: 8px 15px;
  display: inline-block;
}

.content-title {
  background-color: #409eff;
  color: white;
  width: 100px;
}

.goods-company-list td {
  cursor: pointer;
}

/*head/main样式*/
.linshi_zdy {
  position: absolute;
  right: 0px;
  top: 450px;
}
.linshi_zdy li {
  width: 150px;
  font-size: 16px;
  padding: 3px 8px;
  cursor: pointer;
}
.linshi_zdy span {
  display: block;
  width: 14px;
  height: 14px;
  float: left;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 5px;
}
.linshi_zdy li:first-child {
  color: #ff0000;
}
.linshi_zdy li:first-child span {
  background: #ff0000;
}
.linshi_zdy li:nth-child(2) {
  color: #9cff00;
}
.linshi_zdy li:nth-child(2) span {
  background: #9cff00;
}
.linshi_zdy li:nth-child(3) {
  color: #fff;
}
.linshi_zdy li:nth-child(3) span {
  background: #fff;
}
.linshi_zdy li:nth-child(4) {
  color: #f4a100;
}
.linshi_zdy li:nth-child(4) span {
  background: #f4a100;
}

/*container*/
.container {
  width: 100%;
  height: 100%;
  color: #333;
  /* overflow: hidden;*/

  background-size: 100% 100%;
  .header1 {
    width: 100%;
    height: 80px;
    padding: 0 20px;
    min-width: 1366px;
    .t_title {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 2.2em;
      line-height: 80px;
      color: #f6f5ec;
      font-weight: bolder;
    }
    .header_logo {
      padding: 18px 10px 10px 0px;
      a {
        display: block;
        img {
          width: 260px;
        }
      }
    }
    .header_nav {
      margin-left: 20px;
      ul {
        li {
          float: left;
          margin-right: 6px;
          position: relative;
          a {
            display: block;
            height: 80px;
            padding: 0 10px 0 30px;
            line-height: 80px;
            color: #fff;
            :hover {
              border-bottom: 4px solid #4b8df8;
            }
          }
          img {
            float: left;
            position: absolute;
            top: 33px;
            left: 10px;
          }
        }
      }
    }
  }
  .data_content {
    /*overflow-x: hidden;*/
    min-width: 1366px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: calc(100% - 120px);
    .data_head {
      color: #ffffff;
      text-align: left;
      margin-left: 40px;
      position: relative;
      top: -20px;
      .input_box {
        border: 2px solid #409eff;
        color: #f2f6fc;
        height: 40px;
        border-radius: 15px;
        text-align: center;
        vertical-align: middle;
        background-color: transparent;
      }
      $color: #2194e0;
      @keyframes sheen {
        0% {
          transform: skewY(-45deg) translateX(0);
        }
        100% {
          transform: skewY(-45deg) translateX(12.5em);
        }
      }
      .button {
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #d3d7d4;
        border: 2px solid $color;
        font-size: 16px;
        display: inline-block;
        border-radius: 15px;
        transition: all 0.2s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-right: 10px;
        &:before {
          content: "";
          background-color: rgba(255, 255, 255, 0.5);
          height: 100%;
          width: 3em;
          display: block;
          position: absolute;
          top: 0;
          left: -4.5em;
          transform: skewX(-45deg) translateX(0);
          transition: none;
        }
        &:hover {
          cursor: pointer;
          background-color: $color;
          color: #fff;
          border-bottom: 4px solid darken($color, 10%);
          &:before {
            transform: skewX(-45deg) translateX(13.5em);
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }
    .data_main {
      width: calc(100% - 40px);
      margin-bottom: 40px;
      height: 100%;
      margin-left: 20px;
      .main_center {
        width: 75%;
        height: 100%;
        .center_text {
          width: calc(100% - 50px);
          height: 100%;
          margin-left: 25px;
          margin-right: 25px;
          box-sizing: border-box;
          border: 1px solid #2c58a6;
          box-shadow: 0px 0px 6px #2c58a6;
          position: relative;
        }
        .main_title {
          width: 180px;
          height: 35px;
          line-height: 33px;
          background-color: #2c58a6;
          border-radius: 18px;
          position: absolute;
          top: -17px;
          left: 50%;
          margin-left: -90px;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          box-sizing: border-box;
          padding-left: 45px;
          z-index: 1000;
          img {
            position: absolute;
            top: 8px;
            left: 20px;
          }
        }
      }
      .main_right {
        width: 25%;
        height: 100%;
      }
    }
  }
  .l_t_line {
    width: 5px;
    height: 24px;
    left: -3px;
    top: -3px;
  }
  .t_l_line {
    height: 5px;
    width: 26px;
    left: -3px;
    top: -3px;
  }
  .t_line_box {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .t_line_box i {
    background-color: #4788fb;
    box-shadow: 0px 0px 10px #4788fb;
    position: absolute;
  }
  .t_r_line {
    height: 5px;
    width: 26px;
    right: -3px;
    top: -3px;
  }
  .r_t_line {
    width: 5px;
    height: 24px;
    right: -3px;
    top: -3px;
  }
  .l_b_line {
    width: 5px;
    height: 24px;
    left: -3px;
    bottom: -3px;
  }
  .b_l_line {
    height: 5px;
    width: 26px;
    left: -3px;
    bottom: -3px;
  }
  .r_b_line {
    width: 5px;
    height: 24px;
    right: -3px;
    bottom: -3px;
  }
  .b_r_line {
    height: 5px;
    width: 26px;
    right: -3px;
    bottom: -3px;
  }

  .data_content .data_main .main_right > div {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #2c58a6;
    position: relative;
    box-shadow: 0 0 10px #2c58a6;
  }
  .data_content .data_main .main_right div.right_1 .choice {
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 1000;
  }
  .data_content .data_main .main_right div.right_1 .choice label {
    color: #fff;
  }

  .data_content .data_main .main_right div .main_title {
    width: 180px;
    height: 35px;
    line-height: 33px;
    background-color: #2c58a6;
    border-radius: 18px;
    position: absolute;
    top: -17px;
    left: 50%;
    margin-left: -90px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
    padding-left: 45px;
  }
  .data_content .data_main .main_right div .main_title img {
    position: absolute;
    top: 8px;
    left: 20px;
  }

  .data_content .data_bottom div {
  }
  .data_content .data_bottom .bottom_center {
    width: 52%;
    height: 100%;
  }
  .data_content .data_bottom .bottom_2 {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    border: 1px solid #2c58a6;
    /*margin-left: 25px;*/
    box-shadow: 0 0 10px #2c58a6;
  }

  .data_content .data_bottom div .main_title {
    width: 220px;
    height: 35px;
    line-height: 33px;
    background-color: #2c58a6;
    border-radius: 18px;
    position: absolute;
    top: -17px;
    left: 50%;
    margin-left: -110px;
    color: #90d7ec;
    font-size: 18px;
    font-weight: 600;
    box-sizing: border-box;
    padding-left: 45px;
  }
  .data_content .data_bottom div .main_title img {
    position: absolute;
    top: 8px;
    left: 20px;
  }

  .data_content .data_bottom div .main_table tbody {
    overflow: scroll;
    height: 100%;
  }
  .data_content .data_bottom div .main_table tr {
    height: 42px;
    :last-child {
      width: 20%;
    }
  }
  .data_content .data_bottom div .main_table {
    height: 100%;
    width: 100%;
    margin-top: 25px;
  }
  .data_content .data_bottom div .main_table table {
    width: 100%;
  }
  .data_content .data_bottom div .main_table thead tr {
    height: 42px;
  }
  .data_content .data_bottom div .main_table th {
    font-size: 14px;
    font-weight: 600;
    color: #61d2f7;
    text-align: center;
  }
  .data_content .data_bottom div .main_table td {
    color: #f6f5ec;
    font-size: 12px;
    text-align: center;
  }
  .data_content .data_bottom div .main_table tbody tr:nth-child(2n-1) {
    background-color: #072951;
    box-shadow: -10px 0px 15px #2c58a6 inset,
      /*左边阴影*/ 10px 0px 15px #2c58a6 inset; /*右边阴影*/
  }
  .t_btn8 {
    position: relative;
    z-index: 100;
    cursor: pointer;
  }
  .table_zdy a {
    color: #fff;
  }
}
/* bass css*/
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #afb4db;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #afb4db;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #afb4db;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #afb4db;
}
* {
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Light, 微软雅黑;
}
/*各浏览器显示不同，去掉蓝色边框*/
fieldset,
img,
input,
button {
  border: none;
  padding: 0;
  margin: 0;
  outline-style: none;
}
img {
  border: 0;
  vertical-align: middle;
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
/*清除浮动*/
.clear-both:before,
.clear-both:after {
  display: table;
  content: "";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
}
.clearfix {
  *zoom: 1; /*IE/7/6*/
}
.fl {
  float: left;
}
.fr {
  float: right;
}

/*header开始*/

.bg_header {
  width: 100%;
  height: 80px;
  background: url(./images/title.png) no-repeat;
  background-size: 100% 100%;
}

/*content 开始*/

.content {
  margin: 20px;
  width: calc(100% - 40px);
  min-width: 1366px;
}
/*content 结束*/

/*分页 */
.paging {
  padding: 3px;
  font-weight: bold;
  font-size: 14pt;
  margin: 10px;
  color: #fff;
  left: 50%;
  transform: translate(-50%);
  bottom: 45px;
  text-align: center;
}
.paging span {
  margin: 5px;
  color: #4788fb;
  text-decoration: none;
}
.paging span:hover {
  color: #fff;
}
.paging span:active {
  color: #fff;
}
.paging span.current {
  font-weight: bold;
  color: #fff;
}
.paging span.disabled {
  margin: 2px;
  color: #444;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: #1a366a;
  color: #f2f6fc;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
</style>
