<template>
  <div style="padding: 20px; height: 100%">
    <el-form :inline="true" class="demo-form-inline">
      <div class="df aic">
        <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
          <el-input
            placeholder="关键词"
            v-model="query.keyword"
            clearable
            class="input-with-select"
            @clear="onSubmit"
          >
          </el-input>
        </el-form-item>
        <el-button
          type="success"
          size="medium"
          @click="onSubmit"
          class="mr10"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          >查询</el-button
        >
      </div>
    </el-form>
    <el-table :data="companyList" style="width: 100%">
      <el-table-column
        prop="companyName"
        label="企业名称"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="creditCode"
        label="统一社会信用代码"
        width="300"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="companyType"
        label="企业类型"
        width="300"
        align="center"
      >
      </el-table-column>

      <el-table-column prop="address" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="disib"
            size="mini"
            @click="handleOpenDialog(scope.row)"
            >用户列表</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="df" style="justify-content: flex-end; padding: 20px 0">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <!-- <el-dialog title="用户列表" :visible.sync="dialogVisible">
      <el-form :inline="true" class="demo-form-inline">
        <div class="df aic">
          <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
            <el-input
              placeholder="关键词"
              v-model="userQuery.keyword"
              clearable
              class="input-with-select"
              @clear="onSubmit1"
            >
            </el-input>
          </el-form-item>
          <el-button
            type="success"
            size="medium"
            @click="onSubmit1"
            class="mr10"
            style="height: 36px; border: none !important; margin-bottom: 20px"
            >查询</el-button
          >
        </div>
      </el-form>
      <el-table :data="userList">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          property="userName"
          label="用户名"
          width="150"
        ></el-table-column>
        <el-table-column
          property="userMobilephone"
          label="联系电话"
          width="150"
        >
        </el-table-column>
        <el-table-column property="name" label="电子鼻建模" width="150">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.name"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column property="name" label="电子鼻模型删除" width="150">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.name"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <div class="df" style="justify-content: flex-end; padding: 20px 0">
        <el-pagination
          background
          :page-size="userQuery.limit"
          layout="total,prev, pager, next"
          :total="userTotal"
          :current-page="userQuery.page"
          @current-change="pageChange1"
        >
        </el-pagination>
      </div>
    </el-dialog> -->
    <el-dialog
      title="用户权限设置"
      :visible.sync="dialogVisible"
      width="800px"
      custom-class="daily-box"
    >
      <div class="df" style="background-color: #eee">
        <div
          style="
            flex: 1;
            background-color: #fff;
            height: 800px;
            border-radius: 10px;
            padding: 20px;
            transition: transform 250ms, opacity 400ms;
          "
        >
          <div
            class="df record-box"
            style="flex-direction: column; height: 100%"
          >
            <div>
              <el-form :inline="true" class="demo-form-inline">
                <div class="df aic" style="flex-direction: row">
                  <el-form-item
                    size="medium"
                    class="mr10"
                    style="flex: 0 0 300px"
                  >
                    <el-input
                      placeholder="关键词"
                      v-model="userQuery.keyword"
                      clearable
                      class="input-with-select"
                      @clear="onSubmit1"
                    >
                    </el-input>
                  </el-form-item>
                  <el-button
                    type="success"
                    size="medium"
                    @click="onSubmit1"
                    class="mr10"
                    style="
                      height: 36px;
                      border: none !important;
                      margin-bottom: 20px;
                    "
                    >查询</el-button
                  >
                </div>
              </el-form>
            </div>
            <div style="flex: 1">
              <el-table :data="userList">
                <el-table-column
                  property="userName"
                  label="用户名"
                  width="120"
                ></el-table-column>
                <el-table-column
                  property="userMobilephone"
                  label="联系电话"
                  width="120"
                >
                </el-table-column>
                <el-table-column label="设置" width="120">
                  <template slot-scope="scope">
                    <el-button
                      v-if="userCheckedId !== scope.row.userId"
                      type="text"
                      class="disib"
                      size="mini"
                      @click="openPermission(scope.row)"
                      >权限设置</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              class="df"
              style="
                flex-direction: row;
                justify-content: flex-end;
                padding: 20px 0;
              "
            >
              <el-pagination
                background
                :page-size="userQuery.limit"
                layout="total,prev, pager, next"
                :total="userTotal"
                :current-page="userQuery.page"
                @current-change="pageChange1"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div
          style="
            flex: 1;
            background-color: #fff;
            height: 800px;
            margin-left: 10px;
            border-radius: 10px;
          "
        >
          <div
            class="df aic jcc"
            style="padding: 10px 5px 0; font-size: 16px; font-weight: bold"
          >
            {{ userCheckedName }}权限设置
          </div>
          <div
            class="df"
            style="
              height: 100%;
              width: 100%;
              padding: 10px 20px 0;
              flex-direction: column;
            "
          >
            <div style="flex: 1">
              <el-checkbox
                v-model="item.checked"
                :label="item.name"
                border
                size="medium"
                v-for="item in permissionArr"
                :key="item.type"
              ></el-checkbox>
            </div>
            <div
              class="df jcc"
              style="flex-direction: row; padding: 20px 0"
              v-if="isPermissionChange"
            >
              <el-button
                type="success"
                size="medium"
                @click="handleSubmit"
                class="mr10"
                style="
                  height: 36px;
                  border: none !important;
                  margin-bottom: 20px;
                "
                >提交</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "../../../util/api";
import api from "@/util/extra-api";
import map from "../Home/js/map";

export default {
  data() {
    return {
      companyList: [],
      userList: [],
      total: 0,
      userTotal: 0,
      query: {
        keyword: "",
        limit: 18,
        page: 1,
      },
      userQuery: {
        keyword: "",
        companyId: null,
        limit: 11,
        page: 1,
      },
      userCheckedId: null,
      userCheckedName: "",
      dialogVisible: false,
      permissionArr: [
        {
          type: -1,
          checked: false,
          name: "电子鼻基础权限",
        }, //电子鼻建模权限
        {
          type: -2,
          checked: false,
          name: "电子鼻采集权限",
        }, //电子鼻模型删除权限
        {
          type: -3,
          checked: false,
          name: "电子鼻建模权限",
        }, //近红外分类数据读取权限
        {
          type: -4,
          checked: false,
          name: "近红外基础权限",
        }, //近红外分类模型使用权限
        {
          type: -5,
          checked: false,
          name: "近红外采集权限",
        }, //近红外建模权限
        {
          type: -6,
          checked: false,
          name: "近红外建模权限",
        }, //近红外删除权限
        {
          type: -7,
          checked: false,
          name: "近红外混匀度分析权限",
        }, //近红外混匀度权限
      ],
      oleData: [],
    };
  },
  mounted() {
    this.getCompanyList();
  },
  computed: {
    isPermissionChange() {
      let newArr = JSON.stringify(
        this.permissionArr
          .filter((v) => v.checked)
          .map((v) => v.type)
          .sort((a, b) => a - b)
      );
      let oldArr = JSON.stringify(
        this.oleData.map((v) => v).sort((a, b) => a - b)
      );
      return newArr != oldArr;
    },
  },
  methods: {
    handleSubmit() {
      //   console.log(
      //     this.permissionArr.filter((v) => v.checked).map((v) => v.type)
      //   );
      api
        .post("/v1/wx/permissions/pc/auth", {
          userId: this.userCheckedId,
          companyId: this.userQuery.companyId,
          moduleIds: this.permissionArr
            .filter((v) => v.checked)
            .map((v) => v.type),
          type: "noseNearPermission",
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "设置成功",
            });
            this.oleData = this.permissionArr
              .filter((v) => v.checked)
              .map((v) => v.type);
          }
        });
    },
    getCompanyList() {
      api.get("/v1/pc/company/list", this.query).then((res) => {
        if (res.code == 200) {
          this.companyList = res.data;
          this.total = res.total;
        }
      });
    },
    getUserList() {
      api.get("/v1/pc/company/empList", this.userQuery).then((res) => {
        if (res.code == 200) {
          this.userList = res.data;
          this.userTotal = res.total;
          this.openPermission({
            userId: res.data[0].userId,
            userName: res.data[0].userName,
          });
        }
      });
    },
    onSubmit() {
      this.query.page = 1;
      this.getCompanyList();
    },
    onSubmit1() {
      this.userQuery.page = 1;
      this.getUserList();
    },
    pageChange(page) {
      this.query.page = page;
      this.getCompanyList();
    },
    pageChange1(page) {
      this.userQuery.page = page;
      this.getUserList();
    },
    handleOpenDialog(row) {
      this.userQuery.companyId = row.companyId;
      this.userQuery.page = 1;
      this.userQuery.keyword = "";
      this.getUserList();
      this.dialogVisible = true;
    },
    openPermission(row) {
      api
        .get("/v1/wx/permissions/pc/authUser1", {
          userId: row.userId,
          companyId: this.userQuery.companyId,
          type: "noseNearPermission",
        })
        .then((res) => {
          if (res.code == 200) {
            this.userCheckedId = row.userId;
            this.userCheckedName = row.userName;
            this.oleData = res.data;
            this.permissionArr.forEach((item) => {
              item.checked = res.data.includes(item.type);
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.daily-box {
  border-radius: 15px;
  overflow: hidden;
  .el-dialog__body {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #eee !important;
    padding: 10px !important;
  }
}
/deep/.el-checkbox {
  width: 100% !important;
  margin: 5px 0 !important;
  text-align: left !important;
  padding: 10px !important;
  height: auto !important;
}
</style>