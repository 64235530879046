<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>AI模型</el-breadcrumb-item>
        <el-breadcrumb-item>模型绑定</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <div style="padding: 0 20px">
      <el-form :inline="true" :model="query" class="demo-form-inline">
        <div class="df aic">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input placeholder="模型名称" v-model="query.keyword">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <div class="df">
                <el-button
                  type="primary"
                  @click="search"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
                <el-button type="success" @click="addVisible = true"
                  >新增</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </div>
      </el-form>
      <el-table border :data="list" style="width: 100%">
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="companyName" label="绑定企业" width="180">
        </el-table-column>
        <el-table-column prop="modeName" label="模型名称" width="180">
        </el-table-column>
        <el-table-column prop="time" label="绑定时间" width="180">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib blue"
              size="mini"
              @click="handleUpdate(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              class="disib red"
              size="mini"
              @click="delAIModel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="i-page fr disib mt20">
      <el-pagination
        background
        :page-size="query.limit"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="修改"
      :visible.sync="updateVisible"
      width="60%"
      @close="updateVisible = false"
    >
      <div style="flex-direction: column; height: 700px" class="df">
        <div
          style="
            flex: 1;
            height: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0 20px;
            box-sizing: border-box;
          "
          class="add-dialog-box"
        >
          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            label-position="left"
          >
            <el-form-item label="企业名称" prop="supplyName">
              <div style="width: 70%">
                <el-autocomplete
                  placeholder="请输入企业名称名称"
                  v-model="form.companyName"
                  :fetch-suggestions="querySearchAsync"
                  clearable
                  style="width: 400px; float: left"
                  @select="handleSelect"
                >
                  <template slot-scope="{ item }">
                    <span class="name">{{ item.company_name }}</span>
                    <span
                      class="addr"
                      style="font-size: 12px; color: silver; float: right"
                      >{{ item.credit_code }}</span
                    >
                  </template>
                </el-autocomplete>
              </div>
            </el-form-item>
            <el-form-item label="模型名称" prop="supplyName">
              <div style="width: 400px">
                <el-input
                  v-model="form.modeName"
                  placeholder="请输入模型名称"
                  disabled
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
          <el-table
            :data="form.modeContent"
            style="width: 100%"
            ref="updateTable"
            :border="true"
            @select="handleChecked"
            @select-all="handleCheckedAll"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="属性" width="180">
            </el-table-column>
            <el-table-column prop="field" label="字段" width="180">
            </el-table-column>
            <el-table-column prop="info" label="输出项">
              <template slot-scope="scope">
                <el-tag
                  v-for="(tag, tagIndex) in scope.row.info"
                  :key="tagIndex"
                  style="margin: 5px; margin-right: 5px"
                  :type="['success', 'warning', 'danger'][tagIndex % 3]"
                  size="medium"
                >
                  {{ tag }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="df" style="flex-direction: row; justify-content: flex-end">
        <el-button
          slot="reference"
          type="success"
          style="margin: 5px; margin-right: 5px"
          @click="updateAIModel"
          >修改</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="绑定"
      :visible.sync="addVisible"
      width="60%"
      @close="addVisible = false"
    >
      <el-carousel
        v-if="addVisible"
        height="700px"
        indicator-position="none"
        arrow="never"
        :autoplay="false"
        ref="carousel"
      >
        <el-carousel-item>
          <div
            style="
              height: 80%;
              overflow-y: scroll;
              padding: 0 20px;
              box-sizing: border-box;
            "
            class="add-dialog-box"
          >
            <div style="padding: 0 20px">
              <el-table border :data="modelList" style="width: 100%">
                <el-table-column type="index" label="序号" width="55">
                </el-table-column>
                <el-table-column prop="modeName" label="模型名称" width="180">
                </el-table-column>
                <el-table-column
                  prop="createdTime"
                  label="创建时间"
                  width="180"
                >
                </el-table-column>
                <el-table-column prop="value" label="接口" width="180">
                </el-table-column>
                <el-table-column label="操作" width="200">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      class="disib blue"
                      size="mini"
                      @click="chooseModel(scope.row)"
                      >选择模型</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div class="i-page fr disib mt20">
                <el-pagination
                  background
                  :page-size="modelQuery.limit"
                  layout="total,prev, pager, next"
                  :total="total"
                  :current-page="modelQuery.page"
                  @current-change="dialogCurrentChange"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item style="box-sizing: border-box">
          <div style="flex-direction: column; height: 90%" class="df">
            <div
              style="
                flex: 1;
                height: 0;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 0 20px;
                box-sizing: border-box;
              "
              class="add-dialog-box"
            >
              <div class="df" style="margin-bottom: 10px">
                <el-button type="primary" @click="handleBack" size="small"
                  >返回</el-button
                >
              </div>
              <el-form
                ref="form"
                :model="form"
                label-width="80px"
                label-position="left"
              >
                <el-form-item label="企业名称" prop="supplyName">
                  <div style="width: 70%">
                    <el-autocomplete
                      placeholder="请输入企业名称名称"
                      v-model="form.companyName"
                      :fetch-suggestions="querySearchAsync"
                      clearable
                      style="width: 400px; float: left"
                      @select="handleSelect"
                    >
                      <template slot-scope="{ item }">
                        <span class="name">{{ item.company_name }}</span>
                        <span
                          class="addr"
                          style="font-size: 12px; color: silver; float: right"
                          >{{ item.credit_code }}</span
                        >
                      </template>
                    </el-autocomplete>
                  </div>
                </el-form-item>
                <el-form-item label="模型名称" prop="supplyName">
                  <div style="width: 400px">
                    <el-input
                      v-model="form.modeName"
                      placeholder="请输入模型名称"
                      disabled
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <el-table
                :data="form.modeContent"
                style="width: 100%"
                :border="true"
                @select="handleChecked"
                @select-all="handleCheckedAll"
              >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column prop="name" label="属性" width="180">
                </el-table-column>
                <el-table-column prop="field" label="字段" width="180">
                </el-table-column>
                <el-table-column prop="info" label="输出项">
                  <template slot-scope="scope">
                    <el-tag
                      v-for="(tag, tagIndex) in scope.row.info"
                      :key="tagIndex"
                      style="margin: 5px; margin-right: 5px"
                      :type="['success', 'warning', 'danger'][tagIndex % 3]"
                      size="medium"
                    >
                      {{ tag }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            class="df"
            style="flex-direction: row; justify-content: flex-end"
          >
            <el-button
              slot="reference"
              type="success"
              style="margin: 5px; margin-right: 5px"
              @click="addAIModel"
              >绑定</el-button
            >
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </el-container>
</template>

<script>
import api from "@/util/extra-api";
import axios from "@/util/api";

export default {
  data() {
    return {
      list: [],
      total: 0,
      modelList: [],
      modelTotal: 0,
      modelQuery: {
        page: 1,
        limit: 10,
        type: 1,
      },
      addVisible: false,
      form: {
        modeId: null,
        modeName: "",
        companyId: null,
        companyName: "",
        modeContent: [],
      },
      query: {
        keyword: "",
        page: 1,
        limit: 10,
      },
      updateVisible: false,
      chooseId: null,
    };
  },
  mounted() {
    this.getModelList();
    this.getList();
  },
  methods: {
    handleUpdate(row) {
      this.form.companyId = row.companyId;
      this.form.companyName = row.companyName;
      this.form.modeId = row.modeId;
      this.form.modeName = row.modeName;
      this.chooseId = row.id;
      let arr = JSON.parse(row.modeContent).map((v) => v.name);
      api
        .get("/v1/pc/device/getModeContent", { modeId: row.modeId })
        .then((res) => {
          this.$set(
            this.form,
            "modeContent",
            JSON.parse(res.data).map((v, i) => {
              return {
                ...v,
                checked: arr.includes(v.name),
                index: i,
              };
            })
          );
          this.updateVisible = true;
          setTimeout(() => {
            this.form.modeContent
              .filter((v) => v.checked)
              .forEach((row) => {
                this.$refs.updateTable.toggleRowSelection(row);
              });
          }, 200);
        });
    },
    delAIModel(row) {
      api.post("/v1/pc/device/deleteAIModel", { id: row.id }).then(res=>{
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.getList();
      });
    },
    updateAIModel() {
      let data = {
        ...this.form,
        id: this.chooseId,
        modeContent: JSON.stringify(
          this.form.modeContent
            .filter((v) => v.checked)
            .map((v) => {
              return {
                name: v.name,
                field: v.field,
                info: v.info,
              };
            })
        ),
      };
      api.post("/v1/pc/device/updateAIModel", data).then((res) => {
        this.$message({
          type: "success",
          message: "更新成功",
        });
        this.updateVisible = false;
        this.getList();
      });
    },
    search() {
      this.query.page = 1;
      this.getList();
    },
    addAIModel() {
      let data = {
        ...this.form,
        modeContent: JSON.stringify(
          this.form.modeContent
            .filter((v) => v.checked)
            .map((v) => {
              return {
                name: v.name,
                field: v.field,
                info: v.info,
              };
            })
        ),
      };
      // console.log(data);
      api.post("/v1/pc/device/addAIModel", data).then((res) => {
        this.$message({
          type: "success",
          message: "新增成功",
        });
        this.addVisible = false;
        this.getList();
      });
    },
    handleChecked(selection, row) {
      let checkedArr = selection.map((v) => v.index);
      this.form.modeContent.forEach((v, i) => {
        if (checkedArr.length) {
          this.$set(v, "checked", checkedArr.includes(i));
        } else {
          this.$set(v, "checked", false);
        }
      });
    },
    handleCheckedAll(selection) {
      let checkedArr = selection.map((v) => v.index);
      this.form.modeContent.forEach((v, i) => {
        if (checkedArr.length) {
          this.$set(v, "checked", checkedArr.includes(i));
        } else {
          this.$set(v, "checked", false);
        }
      });
    },
    handleSelect(item) {
      // console.log(item, "选择");
      this.form.companyId = item.company_id;
      this.form.companyName = item.company_name;
    },
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios
          .get("/pc/company/signList/2", {
            company_name: this.form.companyName,
          })
          .then((response) => {
            let results = response.data.company_list;
            cb(results);
          });
      } else {
        cb([]);
      }
    },
    chooseModel(row) {
      this.$set(
        this.form,
        "modeContent",
        JSON.parse(row.modeStep).map((v, i) => {
          return {
            ...v,
            checked: false,
            index: i,
          };
        })
      );
      // console.log();
      this.$set(this.form, "modeId", row.id);
      this.$set(this.form, "modeName", row.modeName);
      this.$refs.carousel.next();
    },
    handleBack() {
      this.$refs.carousel.prev();
    },
    dialogCurrentChange(page) {
      this.modelQuery.page = page;
      this.getModelList();
    },
    currentChange(page) {
      this.query.page = page;
      this.getList();
    },
    getList() {
      api.get("/v1/pc/device/AIModelList", this.query).then((res) => {
        this.list = res.data;
        this.total = res.total;
      });
    },
    handleClose() {},
    getModelList() {
      api.get("/v1/pc/device/modeList", this.modelQuery).then((res) => {
        this.modelList = res.data;
        this.modelTotal = res.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-dialog-box::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.add-dialog-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // background: rgba(255, 255, 255, 0.8);
  background: #bbb;
  transition: color 0.2s ease;
  cursor: pointer;
}
/deep/ .el-dialog {
  border-radius: 10px;
}
/deep/ .el-table__empty-block {
  width: 100% !important;
}
</style>