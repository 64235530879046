<template>
  <div class="screen-container">
    <div class="df aic jcc" style="width: 80%; margin: 60px 10% 0">
      <el-carousel
        height="800px"
        style="width: 100%"
        :autoplay="false"
        @change="carouselChange"
        indicator-position="none"
      >
        <el-carousel-item>
          <div class="df aic jcc" :class="isMouseEnter ? 'mouse-enter' : ''">
            <iframe
              src="https://view2.esunadv.com/#/chart/preview/38"
              id="mobsf"
              scrolling="no"
              frameborder="0"
              style="width: 1200px; height: 840px; margin: 0 20px"
            ></iframe>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="df aic jcc" style="width: 80%; margin: 60px 10%">
      <div
        v-if="carouselIndex === 0"
        class="df aic"
        style="flex-wrap: wrap; width: 1200px"
      >
        <div
          class="df aic sensor-box"
          v-for="(item, index) in sensorData1"
          :key="index"
          @click="openSensorDailog(item)"
        >
          <div class="df index-box">
            <div class="df aic jcc index">
              {{ index + 1 }}
            </div>
            <div class="df aic jcc" style="flex: 1">
              <img
                src="./images/online.png"
                alt=""
                style="height: 30px"
                v-if="item.status === 'ONLINE'"
              />
              <img
                src="./images/outline.png"
                alt=""
                style="height: 30px"
                v-else
              />
            </div>
          </div>
          <div style="flex: 1" class="df aic info-box">
            <div style="color: #000; text-align: right; line-height: 22px">
              {{ item.name }}
            </div>
            <div class="value df aic">
              {{ item.value }}
              <span
                style="
                  font-size: 16px;
                  font-wight: 700;
                  color: red;
                  line-height: 22px;
                "
                >{{ item.unit }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else class="df aic" style="flex-wrap: wrap; width: 1200px">
        <div
          class="df aic sensor-box"
          v-for="(item, index) in sensorData2"
          :key="index"
          @click="openSensorDailog(item)"
        >
          <div class="df index-box">
            <div class="df aic jcc index">
              {{ index + 1 }}
            </div>
            <div style="flex: 1" class="df aic jcc">
              <img
                src="./images/online.png"
                alt=""
                style="height: 30px"
                v-if="item.status === 'ONLINE'"
              />
              <img
                src="./images/outline.png"
                alt=""
                style="height: 30px"
                v-else
              />
            </div>
          </div>
          <div style="flex: 1" class="df aic info-box">
            <div style="color: #000; text-align: right; line-height: 22px">
              {{ item.name }}
            </div>
            <div class="value df aic">
              {{ item.value }}
              <span
                style="
                  font-size: 16px;
                  font-wight: 700;
                  color: red;
                  line-height: 22px;
                "
                >{{ item.unit }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="videoTitle" :visible.sync="videoShow">
      <div class="df aic" style="height: 30px; justify-content: flex-end">
        <el-button
          v-if="isRealTime"
          type="text"
          class="disib blue"
          size="mini"
          @click="handleReplyPics"
          >留影回看</el-button
        >
        <el-button
          v-else
          type="text"
          class="disib blue"
          size="mini"
          @click="isRealTime = true"
          >查看直播</el-button
        >
      </div>
      <div v-if="isRealTime">
        <iframe
          :src="videoUrl"
          frameborder="0"
          width="100%"
          height="980px"
          scrolling="auto"
          ref="video-player"
        ></iframe>
      </div>
      <div v-else>
        <div>
          <el-date-picker
            v-model="timeValue"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 100%"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            @change="handleTimeChange"
          >
          </el-date-picker>
        </div>
        <div style="margin-top: 10px" v-loading="picLoading">
          <el-image
            :src="imageSrc"
            :preview-src-list="[imageSrc]"
            style="width: 100%"
          >
          </el-image>
        </div>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6" style="text-align: center">
            <el-button size="mini" @click="handlePre">上一张</el-button>
          </el-col>

          <el-col :span="6" style="text-align: center">
            <el-button size="mini" @click="handleNext">下一张</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogTableVisible">
      <div style="margin-bottom: 10px">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <el-button
          type="primary"
          size="small"
          @click="onSearch"
          style="margin-left: 10px"
          >筛选</el-button
        >
        <el-button
          v-if="buttonFlag"
          type="success"
          size="small"
          @click="onChangeChart"
          >切换图表</el-button
        >
      </div>
      <transition name="el-fade-in-linear">
        <div v-show="lineChartFlag">
          <ve-line
            ref="lineChart"
            :loading="lineChartLoading"
            :data="lineChartData"
            :extend="lineChartExtend"
            :settings="lineChartSettings"
          ></ve-line>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div v-show="!lineChartFlag">
          <el-table
            :data="gridData"
            border
            fit
            height="800"
            v-loading="sensorLoading"
          >
            <el-table-column
              align="center"
              property="data"
              label="值"
            ></el-table-column>
            <el-table-column
              align="center"
              property="time"
              label="时间"
            ></el-table-column>
          </el-table>
          <div class="pagination-container" v-show="pageFlag">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="hisQuery.page"
              :page-size="50"
              layout="total, prev, pager, next"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </transition>
    </el-dialog>
  </div>
</template>

<script>
import "echarts/lib/component/visualMap";
import api from "../../../util/extra-api";
import { pickerOptions, seriesOption } from "../sensor_screen/optionData";
import dayjs from "dayjs";
import axios from "../../../util/api";

export default {
  data() {
    this.lineChartExtend = {
      "xAxis.0.axisLabel.rotate": 45,
    };
    this.lineChartSettings = {
      labelMap: {
        time: "时间",
        value: "值",
      },
    };
    return {
      isRealTime: true,
      pickerOptions,
      seriesOption,
      videoTitle: "",
      videoUrl: "",
      showCam: false,
      sensorData1: [],
      sensorData2: [],
      lineChartFlag: false,
      hisQuery: {
        id: null,
        page: 1,
        companyId: 2582269,
        limit: 50,
        startTime: "",
        endTime: "",
      },
      carouselIndex: 0,
      videoShow: false,
      showSensorList: false,
      curIndex: null,
      title: null,
      gridData: [],
      total: 0,
      dialogTableVisible: false,
      sensorLoading: false,
      lineChartData: {},
      lineChartLoading: false,
      buttonFlag: false,
      dateRange: "",
      pageFlag: false,
      camId: null,
      imageSrc: "",
      picLoading: false,
      timeValue: null,
      isMouseEnter: true,
    };
  },
  computed: {},
  mounted() {
    window.addEventListener(
      "message",
      (e) => {
        if (e.origin === "https://view2.esunadv.com") {
          console.log(e);
          if (e.data.type === "sensor") {
            // this.getHistoryData(e.data.id);
            this.openHistoryData(e.data.id);
          } else if (e.data.type === "cam") {
            this.camId = e.data.id;
            this.videoShow = true;
            this.videoTitle = e.data.name;
            this.videoUrl = "https://extlive.esunadv.com/?url=" + e.data.url;
          }
        }
      },
      false
    );
    this.getSensorData();
  },
  watch: {
    "lineChartData.rows"(v) {
      this.$nextTick((_) => {
        this.$refs[`lineChart`].echarts.resize();
      });
    },
    dialogTableVisible(v) {
      if (!v) {
        this.hisQuery.startTime = "";
        this.hisQuery.endTime = "";
        this.hisQuery.page = 1;
      }
    },
    videoShow(v) {
      if (!v) {
        this.imageSrc = "";
        this.isRealTime = true;
      }
    },
  },
  methods: {
    handleTimeChange(time) {
      this.picLoading = true;
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    handlePre() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
        .subtract(1, "minute")
        .format("YYYY-MM-DD HH:mm");
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    handleNext() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
        .add(1, "minute")
        .format("YYYY-MM-DD HH:mm");
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    handleReplyPics() {
      this.isRealTime = false;
      this.timeValue = dayjs().format("YYYY-MM-DD HH:mm");
      this.picLoading = true;
      axios
        .get("/pc/company-cam/camImg", {
          camId: this.camId,
          time: this.timeValue,
        })
        .then((response) => {
          this.imageSrc = response.data;
          this.picLoading = false;
        });
    },
    openSensorDailog(item) {
      this.hisQuery.id = item.id;
      this.dateRange = [];
      this.buttonFlag = false;
      this.sensorLoading = true;
      this.lineChartData = {};
      this.dialogTableVisible = true;
      this.lineChartFlag = false;
      const name = this.carouselIndex
        ? this.sensorData2.filter((v) => v.id === item.id)[0].name
        : this.sensorData1.filter((v) => v.id === item.id)[0].name;
      this.title = `历史数据[${name}]`;
      this.hisQuery.id = item.id;
      // this.hisQuery.companyId = localStorage.getItem("company_id")?localStorage.getItem("company_id"):2582225;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.sensorLoading = false;
        this.total = data.total;
        this.pageFlag = true;
      });
    },
    onSearch() {
      if (this.dateRange && this.dateRange.length > 0) {
        this.sensorLoading = true;
        this.hisQuery.page = 1;
        this.pageFlag = true;
        this.buttonFlag = true;
        this.hisQuery.startTime = this.dateRange[0];
        this.hisQuery.endTime = this.dateRange[1];
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data;
            this.gridData = data.data.map((m) => {
              return {
                data: m.value + m.unit,
                time: m.timeDate,
              };
            });
            this.total = data.total;
            this.sensorLoading = false;
          });
      } else {
        this.$message({
          message: "请先选择时间区间",
          type: "warning",
        });
      }
    },
    onChangeChart() {
      this.lineChartFlag = !this.lineChartFlag;

      if (this.lineChartFlag) {
        this.lineChartLoading = true;
        api
          .get("/v1/wx/device/sensorHisData", this.hisQuery)
          .then((response) => {
            const data = response.data.data;
            this.lineChartData = {
              columns: ["timeDate", "value"],
              rows: data,
            };
            this.lineChartLoading = false;
          });
      }
    },
    handleCurrentChange(val) {
      this.hisQuery.page = val;
      this.sensorLoading = true;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.total = data.total;
        this.sensorLoading = false;
      });
    },
    openHistoryData(id) {
      this.dateRange = [];
      this.buttonFlag = false;
      this.sensorLoading = true;
      this.lineChartData = {};
      this.dialogTableVisible = true;
      this.lineChartFlag = false;
      const name = this.carouselIndex
        ? this.sensorData2.filter((v) => v.id === id)[0].name
        : this.sensorData1.filter((v) => v.id === id)[0].name;
      this.title = `历史数据[${name}]`;
      this.hisQuery.id = id;
      // this.hisQuery.companyId = localStorage.getItem("company_id")?localStorage.getItem("company_id"):2582225;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((response) => {
        const data = response.data;
        this.gridData = data.data.map((m) => {
          return {
            data: m.value + m.unit,
            time: m.timeDate,
          };
        });
        this.sensorLoading = false;
        this.total = data.total;
        this.pageFlag = true;
      });
    },
    carouselChange(index) {
      this.carouselIndex = index;
    },
    getSensorData() {
      api.get(`/v1/wx/device/fsSensorChart?companyId=2582269`).then((res) => {
        // let sortArr1 = [252, 245, 246, 248, 253, 244, 249, 251, 250];
        let sortArr1 = [252, 245, 248, 253, 244, 249, 251];
        this.sensorData1 = res.data
          .filter((v) => sortArr1.includes(v.id))
          .sort((a, b) => sortArr1.indexOf(a.id) - sortArr1.indexOf(b.id));
      });
    },
    getHistoryData(id) {
      this.hisQuery.id = id;
      api.get("/v1/wx/device/sensorHisData", this.hisQuery).then((res) => {
        const data = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.screen-container {
  background: url(./bg.png) no-repeat;
  /*background-repeat: no-repeat;*/
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  min-height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: left;
}
.jcc {
  justify-content: center;
}
.aic {
  align-items: center;
}
.sensor-box:hover {
  cursor: pointer;
}
.sensor-box {
  width: 380px;
  height: 60px;
  box-sizing: border-box;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  margin: 10px;
  .index-box {
    width: 80px;
    height: 30px;
    border-radius: 10px;
    background-color: #eee;
    .index {
      background-color: #00baff;
      color: #fff;
      font-weight: 700;
      flex: 0 0 30px;
      border-radius: 10px;
    }
  }
  .info-box {
    justify-content: flex-end;
    .value {
      margin-left: 10px;
      justify-content: flex-end;
      font-size: 22px;
      font-weight: 700;
      color: #00baff;
    }
  }
}
</style>
